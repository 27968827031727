import React from "react";

const DesignElements = () => {
	return (
		<>
			<img
				src='/assets/Mobile/Services/ImagineSection/stars.svg'
				alt='Stars'
				className='absolute -top-[40px] left-[90px] animate-pulse'
			/>
             <img
				src='/assets/Mobile/Services/ImagineSection/yellow-bulb.svg'
				alt='Yellow bulb'
				className='absolute top-[305px] left-[290px] '
			/>
            <img
				src='/assets/Mobile/Services/ImagineSection/pink-bulb.svg'
				alt='Pink bulb'
				className='absolute top-[360px] left-[200px] '
			/>
            <img
				src='/assets/Mobile/Services/ImagineSection/star.svg'
				alt='Star'
				className='absolute top-[380px] left-[160px] animate-bounce'
			/>
		</>
	);
};

export default DesignElements;
