import React from "react";
import TextElements from "./TextElements";
import DesignElements from "./DesignElements";

const NewHeights = ({ isVisible }: { isVisible: boolean }) => {
	return (
		<div className='h-[1080px] relative flex flex-col items-center justify-center widthClass overflow-x-clip'>
			<div className='w-[833px] h-[565px] flex flex-col items-center justify-center  '>
				<TextElements isVisible={isVisible} />
				<DesignElements />
			</div>
		</div>
	);
};

export default NewHeights;
