import HeadingTextAnimation from "components/HeadingTextAnimation";
import React from "react";

const HeadingText = ({ isVisible }: { isVisible: boolean }) => {
	return (
		<div className='relative w-fit h-fit'>
			<img
				src='/assets/Mobile/Home/ClosingSection/text-rect.svg'
				alt='Border around text'
				className={`-rotate-[4.35deg]`}
			/>
			<div className='font-poppinsB text-[18.61px] text-white leading-[111%] absolute top-[50%] left-[50%] transform translate-x-[-50%] translate-y-[-50%]'>
				<div className='flex items-end  w-fit h-fit gap-x-[5px] leading-[114.5%]'>
					<HeadingTextAnimation
						text={"With"}
						smallText
						isVisible={isVisible}
						idx={"1"}
					/>

					<HeadingTextAnimation
						text={"10+ years"}
						isVisible={isVisible}
						idx={"1"}
						extraClass='uppercase text-squeeze-yellow font-poppinsBl text-[22.55px]  '
						smallText
					/>
				</div>

				<HeadingTextAnimation
					text={"of experience in the"}
					isVisible={isVisible}
					smallText
					idx={"1"}
				/>

				<HeadingTextAnimation
					text={"industry, we came"}
					isVisible={isVisible}
					smallText
					idx={"1"}
				/>

				<HeadingTextAnimation
					text={"together to build"}
					isVisible={isVisible}
					smallText
					idx={"1"}
				/>

				<HeadingTextAnimation
					text={"Squeeze Creative."}
					isVisible={isVisible}
					smallText
					idx={"3"}
					extraClass='uppercase text-squeeze-yellow font-poppinsBl text-[22.55px] h-fit leading-[114.5%]'
				/>
			</div>
		</div>
	);
};

export default HeadingText;
