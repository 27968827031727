
import HeadingText from "./HeadingText";
import DescText from "./DescText";

const ClosingSection = ({ isVisible }: { isVisible: boolean }) => {
	return (
		<div className='h-[565px] w-[833px] flex flex-col items-center justify-center  overflow-x-visible absolute top-[333px] left-[372px]'>
			<div className='w-[833px] h-[565px] flex flex-col items-center justify-center  '>
				<HeadingText isVisible={isVisible}/>
				<DescText isVisible={isVisible}/>
			</div>
		
		</div>
	);
};

export default ClosingSection;
