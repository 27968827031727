import { gsap } from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import { useGSAP } from "@gsap/react";
gsap.registerPlugin(ScrollTrigger);

const Cards = () => {
	useGSAP(() => {
		const timelinePs = gsap.timeline({
			scrollTrigger: {
				trigger: "#pinkCont",
				scroller: "#scrollDemo",
				start: "top center",
				end: "center center",

				scrub: 5,
			},
		});
		timelinePs.fromTo(
			"#strug",

			{ position: "absolute", x: 335, y: 210, rotate: -10 },
			{ position: "absolute", x: 335, y: 143, rotate: 0 }
		);

		const timelinePs2 = gsap.timeline({
			scrollTrigger: {
				trigger: "#pinkCont",
				scroller: "#scrollDemo",
				start: "top center",
				end: "center center",

				scrub: 6,
			},
		});
		timelinePs2.fromTo(
			"#eng",

			{ position: "absolute", x: 750, y: 50, scale: 1.1 },
			{ position: "absolute", x: 750, y: 115, scale: 1 }
		);
		const timelinePs3 = gsap.timeline({
			scrollTrigger: {
				trigger: "#pinkCont",
				scroller: "#scrollDemo",
				start: "top center",
				end: "center center",

				scrub: 6,
			},
		});
		timelinePs3.fromTo(
			"#wres",

			{ position: "absolute", x: 565, y: 544, scale: 0.75 },
			{ position: "absolute", x: 565, y: 444, scale: 1 }
		);
	}, []);

	return (
		<div
			className='text-center  w-[1512px] h-[833px] text-squeeze-darkBlue'
			id='pinkCont'
		>
			<div id='strug' className=' w-fit h-fit'>
				<div className='relative flex items-center justify-center transition-transform duration-300 ease-in-out hover:scale-110'>
					{" "}
					<img
						src='/assets/QCSection/black-rectangle.svg'
						alt='black rectangle'
					/>
					<p className='absolute text-[40px] font-poppinsM -rotate-2 text-white leading-[106%]'>
						Struggling
						<br />
						with online
						<br />
						obscurity?
					</p>
				</div>
			</div>
			<div id='eng' className=' w-fit h-fit'>
				<div className='flex items-center justify-center transition-transform duration-300 ease-in-out hover:scale-110'>
					<img
						src='/assets/QCSection/white-rectangle.svg'
						alt='white rectangle'
					/>
					<p className='absolute  text-[44px] font-poppinsB rotate-[8deg] leading-[111.5%]'>
						Engagement
						<br />
						eluding you on
						<br />
						social media?
					</p>
				</div>
			</div>
			<div id='wres' className=' w-fit h-fit'>
				<div className='flex items-center justify-center  hover:scale-110 transition-transform duration-300 ease-in-out  -rotate-[6.98deg]'>
					<img
						src='/assets/QCSection/yellow-rectangle.svg'
						alt='yellow rectangle'
					/>
					<p className='absolute text-[44px] font-poppinsI  leading-[111.5%]'>
						Wrestling
						<br />
						with brand
						<br />
						inconsistency?
					</p>
				</div>
			</div>
		</div>
	);
};

export default Cards;
