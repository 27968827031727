import { Link } from "react-router-dom";
import tStyles from "./textSizes.module.css";
import { BlogPost } from "lib/interfaces";
import { formatDate } from "lib/utils";

const CarouselElement = ({ featuredPost }: { featuredPost: BlogPost }) => {
	return (
		<div className='relative flex items-start justify-start w-full tablet:aspect-[2.5]  '>
			<div
				style={{ paddingLeft: "7%", paddingRight: "7%" }}
				className='text-white break:w-[704px] break:px-[94px] tablet:w-[60%] z-20 w-full bg-squeeze-pink flex flex-col items-start tablet:justify-end justify-start max-tablet:pt-6 max-tablet:h-full break:space-y-[20px] rounded-[27px] aspect-[1.487] tablet:pb-[6%] break:pb-[55px]'
			>
				<p
					className={`${tStyles.catText} uppercase tracking-[0.11em] text-squeeze-yellow font-robotom `}
				>
					{featuredPost.category.title}
				</p>
				<p
					className={`break:${
						featuredPost.title.length > 80 ? "text-[40px]" : "text-[50px]"
					} max-mobile-m:line-clamp-3 laptop-l:text-[40px] laptop:text-[30px] tablet:text-[20px] mobile-l:text-[20px] font-poppinsEb tracking-[-0.015em] leading-[112%]`}
				>
					{featuredPost.title}
				</p>
				<div className={`flex w-full gap-3 ${tStyles.infoText}`}>
					<p className='font-roboto '>by {featuredPost.author.name}</p>
					<p className='font-robotoL '>|</p>
					<p className='font-robotoL '>{formatDate(featuredPost.createdAt)}</p>
				</div>
				<Link
					to={`/blog/${featuredPost.title}/${featuredPost.id}`}
					className={`max-tablet:hidden aspect-[3.74] uppercase flex items-center justify-center rounded-full bg-transparent border-[0.93px] hover:bg-squeeze-yellow hover:text-squeeze-darkBlue border-squeeze-yellow w-[146px] h-[39px] text-[16px] font-poppinsSb text-squeeze-yellow `}
				>
					Read more
				</Link>
			</div>
			<div className='aspect-[1.487] absolute right-0 z-10 break:w-[710px] tablet:w-[60%] bg-squeeze-yellow bg-opacity-50 rounded-[27px]'>
				{featuredPost.banner?.image ? (
					<img
						src={featuredPost.banner.image}
						alt='filler'
						className='object-cover aspect-[1.487] w-full rounded-[27px]'
					/>
				) : (
					<img
						src='/assets/temp/bullseye.png'
						alt='filler'
						className='object-cover aspect-[1.487] w-full rounded-[27px]'
					/>
				)}
			</div>
		</div>
	);
};

export default CarouselElement;
