import React from "react";
import { gsap } from "gsap";

import { useGSAP } from "@gsap/react";

import ScrollTrigger from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const ServicesParallaxElements = () => {
	useGSAP(() => {
		const timelinecapBR = gsap.timeline({
			scrollTrigger: {
				trigger: "#cont",
				scroller: "#scrollDemo",
				start: "top center",
				end: "center center",

				scrub: 3,
			},
		});
		timelinecapBR.fromTo(
			"#capsuleBR",
			{ x: 980, y: 600, scale: 2, rotate: 90 },
			{ position: "relative", x: 980, y: 410, scale: 1, rotate: 0 }
		);

		const timelinecapTR = gsap.timeline({
			scrollTrigger: {
				trigger: "#cont",
				scroller: "#scrollDemo",
				start: "top center",
				end: "center center",
				scrub: 3,
			},
		});
		timelinecapTR.fromTo(
			"#capsuleTR",
			{ x: 930, y: 330, scale: 0.7, rotate: 30 },
			{ position: "relative", x: 930, y: 400, scale: 1, rotate: 0 }
		);

		const timelineCircTR = gsap.timeline({
			scrollTrigger: {
				trigger: "#cont",
				scroller: "#scrollDemo",
				start: "top center",
				end: "center center",
				scrub: 4,
			},
		});
		timelineCircTR.fromTo(
			"#circleTR",
			{ x: 1120, y: 700, scale: 3 },
			{ x: 1120, y: 360, scale: 1}
		);

		const timelineCircTL = gsap.timeline({
			scrollTrigger: {
				trigger: "#cont",
				scroller: "#scrollDemo",
				start: "top center",
				end: "center center",

				scrub: 7,
			},
		});
		timelineCircTL.fromTo(
			"#circleTL",
			{ x: 250, y: 600, scale: 0.3 },
			{ x: 250, y: 380, scale: 1 }
		);

		const timelineRectL = gsap.timeline({
			scrollTrigger: {
				trigger: "#cont",
				scroller: "#scrollDemo",
				start: "top center",
				end: "center center",

				scrub: 5,
			},
		});
		timelineRectL.fromTo(
			"#rectL",
			{ x: 0, y: 100, scale: 2, rotate: -30 },
			{ x: 220, y: 520, scale: 1, rotate: 0 }
		);

		const timelineCircleBL = gsap.timeline({
			scrollTrigger: {
				trigger: "#cont",
				scroller: "#scrollDemo",
				start: "top center",
				end: "center center",
				scrub: 8,
			},
		});

		timelineCircleBL.fromTo(
			"#circleBL",
			{ x: 500, y: 1200, scale: 2.5 },
			{ position: "relative", x: 500, y: 500, scale: 1 }
		);
	}, []);
	return (
		<>
			<img
				src='/assets/ServicesSection/circle-top-right.svg'
				alt='Top right pink circle'
				id='circleTR'
				className="absolute"
			/>
			<img
				src='/assets/ServicesSection/capsule-top-right.svg'
				alt='Top right pink capsule'
				id='capsuleTR'
				className='absolute z-10'
			/>
			<img
				src='/assets/ServicesSection/circle-top-left.svg'
				alt='Top left pink circle'
				id='circleTL'
			/>
			<img
				src='/assets/ServicesSection/rectangle-left.svg'
				alt='Left side pink rectangle'
				id='rectL'
			/>
			<img
				src='/assets/ServicesSection/circle-bottom.svg'
				alt='Bottom left pink circle'
				id='circleBL'
				className='z-10'
			/>

			<img
				src='/assets/ServicesSection/capsule-bottom-right.svg'
				alt='Bottom right pink capsule'
				id='capsuleBR'
				className='z-20'
			/>
		</>
	);
};

export default ServicesParallaxElements;
