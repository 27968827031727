import ChatButton from "components/ChatButton";
import { toTop } from "lib/utils";
import { Link } from "react-router-dom";

const Header = () => {
	return (
		<div className=' z-40 flex items-center w-full justify-center mx-[80px] h-[65px]'>
			<div className='relative flex items-center justify-between w-full'>
				<Link to='/'>
					<img
						src='/assets/logo.svg'
						alt='Squeeze creative agency logo '
						className='scale-75'
					/>
				</Link>
				<div
					className={`flex w-[326px] justify-between text-squeeze-darkBlue text-[14px] font-roboto `}
				>
					<div className={` flex items-center justify-center hover:text-squeeze-pink`}>
						<Link 
						onClick={() => {
							toTop()
						}}
						to='/'>home</Link>
					</div>
					<div className={` flex items-center justify-center hover:text-squeeze-pink`}>
						<Link 
						onClick={() => {
							toTop()
						}}
						to='/services'>services</Link>
					</div>
					<div className={` flex items-center justify-center hover:text-squeeze-pink`}>
						<Link 
						onClick={() => {
							toTop()
						}}
						to='/blog'>blog</Link>
					</div>
					<div className={` flex items-center justify-center hover:text-squeeze-pink`}>
						<Link 
						to='/services/faq'>FAQ</Link>
					</div>
					<div className={` flex items-center justify-center`}>
						<ChatButton />
					</div>
				</div>
			</div>
		</div>
	);
};

export default Header;
