import React from "react";
import ServicesParallaxElements from "./ServicesParallaxElements";
import ServiceLinks from "./ServiceLinks";

const ServicesSection = () => {
	return (
		<div className='h-[1490px] relative flex flex-col items-center justify-center widthClass overflow-x-visible'>
			<div className='w-[1512px] h-[1490px] flex flex-col items-center justify-center '>
				<div className='widthClass h-[1490px] realtive' id='cont'>
					<ServicesParallaxElements />
					<ServiceLinks />
				</div>
			</div>
		</div>
	);
};

export default ServicesSection;
