import { gsap } from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import { useGSAP } from "@gsap/react";
gsap.registerPlugin(ScrollTrigger);

const MobileCards = () => {
	useGSAP(() => {
		const timelinePs = gsap.timeline({
			scrollTrigger: {
				trigger: "#pinkCont",
				scroller: "#scrollDemo",
				start: "top center",
				end: "center center",

				scrub: 5,
			},
		});
		timelinePs.fromTo(
			"#strugM",

			{ position: "absolute", x: -50, y:20, rotate: -10 },
			{ position: "absolute", x: -50, y:0, rotate: -7.31 }
		);

		const timelinePs2 = gsap.timeline({
			scrollTrigger: {
				trigger: "#pinkCont",
				scroller: "#scrollDemo",
				start: "top center",
				end: "center center",

				scrub: 6,
			},
		});
		timelinePs2.fromTo(
			"#engM",

			{ position: "absolute", x: -100, y: 190, scale: 1.1 },
			{ position: "absolute", x: -100, y: 170, scale: 1,  rotate: 7.35 }
		);
		const timelinePs3 = gsap.timeline({
			scrollTrigger: {
				trigger: "#pinkCont",
				scroller: "#scrollDemo",
				start: "top center",
				end: "center center",

				scrub: 6,
			},
		});
		timelinePs3.fromTo(
			"#wresM",

			{ position: "absolute", x: -85, y: 330, scale: 0.75 },
			{ position: "absolute", x: -85, y: 310, scale: 1, rotate: -2.29 }
		);
	}, []);

	return (
		<div className=' h-[805px]  text-squeeze-darkBlue' id='pinkCont'>
			<img
				src='/assets/Mobile/Home/PinkSections/1.svg'
				alt='black rectangle'
				id='strugM'
			/>

			<img
				src='/assets/Mobile/Home/PinkSections/2.svg'
				alt='white rectangle'
				id='engM'
			/>

			<img
				src='/assets/Mobile/Home/PinkSections/3.svg'
				alt='black rectangle'
				id='wresM'
			/>
		</div>
	);
};

export default MobileCards;
