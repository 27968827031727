import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
const GBParallaxElements = () => {
useGSAP(() => {
    const timelineSmallStars = gsap.timeline({
        scrollTrigger: {
            trigger: "#gbSection",
            scroller: "#scrollDemo",
            start: "top center",
            scrub: 5,
        },
    });
    timelineSmallStars.fromTo("#smallStars", { x: 90, y: -260 }, { x: 90, y: -260 });

    const timelineBigStar = gsap.timeline({
        scrollTrigger: {
            trigger: "#gbSection",
            scroller: "#scrollDemo",
            start: "top bottom",
            scrub: 4,
        },
    });
    timelineBigStar.fromTo("#bigStar", { x: 255, y: 400, rotate: 15 }, { x: 115, y: 500, rotate: 0 });
}, []);
  return (
    <>
    <img
        src='/assets/Mobile/Home/PinkSections/big-starGB.svg'
        id='bigStar'
        alt='Big yellow star'
        className=' abasolute'
    />
    <img
        src='/assets/Mobile/Home/PinkSections/starsGB.svg'
        id='smallStars'
        alt='Small yellow stars'
        className='absolute'
    />
</>
  )
}

export default GBParallaxElements