import { gsap } from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import { useGSAP } from "@gsap/react";
gsap.registerPlugin(ScrollTrigger);

const DesignElements = () => {
	useGSAP(() => {
		const timelineCloudTLO = gsap.timeline({
			scrollTrigger: {
				trigger: "#valuesSection",
				scroller: "#scrollDemo",
				start: "top center",
				end: "center center",

				scrub: 6,
			},
		});
		timelineCloudTLO.fromTo(
			"#cloudTLO",

			{
				position: "relative",
				x: 70,
				y: 135,
			},
			{
				position: "relative",
				x: 235,
				y: 135,
			}
		);
		const timelineCloudTL = gsap.timeline({
			scrollTrigger: {
				trigger: "#valuesSection",
				scroller: "#scrollDemo",
				start: "top center",
				end: "center center",

				scrub: 6,
			},
		});
		timelineCloudTL.fromTo(
			"#cloudTL",

			{
				position: "absolute",
				x: 500,
				y: 140,
			},
			{
				position: "absolute",
				x: 315,
				y: 140,
			}
		);

		const timelineCloudR = gsap.timeline({
			scrollTrigger: {
				trigger: "#valuesSection",
				scroller: "#scrollDemo",
				start: "top center",
				end: "center center",

				scrub: 8,
			},
		});
		timelineCloudR.fromTo(
			"#cloudR",

			{
				opacity: 10,
				scale: 0.25,
			},
			{
				opacity: 100,
				scale: 1,
			}
		);

		const timelineCloudBRO = gsap.timeline({
			scrollTrigger: {
				trigger: "#valuesSection",
				scroller: "#scrollDemo",
				start: "top center",
				end: "60% center",

				scrub: 8,
			},
		});
		timelineCloudBRO.fromTo(
			"#cloudBRO",

			{
				position: "absolute",
				x: 1000,
				y: 405,
			},
			{
				position: "absolute",
				x: 835,
				y: 405,
			}
		);

		const timelineCloudBR = gsap.timeline({
			scrollTrigger: {
				trigger: "#valuesSection",
				scroller: "#scrollDemo",
				start: "top center",
				end: "70% center",

				scrub: 5,
			},
		});
		timelineCloudBR.fromTo(
			"#cloudBR",

			{
				position: "absolute",
				x: 400,
				y: 510,
				scale: 0.25,
			},
			{
				position: "absolute",
				x: 725,
				y: 510,
				scale: 1,
			}
		);
	}, []);

	return (
		<>
			<img
				src='/assets/ServicesPage/PinkSections/Values/stars.svg'
				alt='stars'
				className='absolute top-[305px] right-[295px] z-0 animate-pulse'
			/>
			<img
				src='/assets/ServicesPage/PinkSections/Values/star.svg'
				alt='star'
				className='absolute bottom-[230px] left-[420px] z-0 animate-pulse'
			/>
			<img
				src='/assets/ServicesPage/PinkSections/Values/cloud-tl-o.svg'
				alt='Cloud outline top left'
				className='z-0 '
				id='cloudTLO'
			/>
			<img
				src='/assets/ServicesPage/PinkSections/Values/cloud-tl.svg'
				alt='Cloud top left'
				className='z-20'
				id='cloudTL'
			/>
			<img
				src='/assets/ServicesPage/PinkSections/Values/cloud-r.svg'
				alt='Cloud right'
				className='absolute top-[510px] right-[315px] z-20'
				id='cloudR'
			/>
			<img
				src='/assets/ServicesPage/PinkSections/Values/cloud-br-o.svg'
				alt='Cloud outline bottom right'
				className='z-0 '
				id='cloudBRO'
			/>
			<img
				src='/assets/ServicesPage/PinkSections/Values/cloud-m.svg'
				alt='Cloud bototm middle'
				className='z-20'
				id='cloudBR'
			/>
		</>
	);
};

export default DesignElements;
