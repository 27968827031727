import { useGSAP } from "@gsap/react";
import Footer from "components/Footer/Footer";
import Headers from "components/Header/Headers";
import FAQ from "components/Services/FAQ/FAQ";
import ImagineSection from "components/Services/ImagineSection/ImagineSection";
import NewHeights from "components/Services/NewHeightsSection/NewHeights";
import ServiceDetails from "components/Services/PinkSections/ServiceDetails/ServiceDetails";
import Values from "components/Services/PinkSections/Values/Values";
import { usePopup } from "contexts/PopupContext";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import ScrollToPlugin from "gsap/dist/ScrollToPlugin";
import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

const Services: React.FC = () => {

	const location = useLocation()

	useGSAP(() => {
		const sections = document.querySelectorAll<HTMLElement>("[data-bgcolor]");
		if (visibleSection === null) {
			setVisibleSection("imagine");
			setPrevVisibleSection("imagine");
		}
		sections.forEach((section, i) => {
			const prevBgColor =
				i === 0 ? "#ffffff" : sections[i - 1].dataset.bgcolor || "";

			gsap.timeline({
				scrollTrigger: {
					trigger: section,
					scroller: "#scrollDemo",
					start: "top 50%",
					end: "bottom 50%",
					onEnter: () => {
						if (prevVisibleSection !== section.id) {
							setVisibleSection(section.id || null);
							setPrevVisibleSection(section.id);
						}
						gsap.to("#scrollDemo", {
							backgroundColor: section.dataset.bgcolor,
							color: section.dataset.textcolor,
							overwrite: "auto",
						});
						gsap.to(section, {
							opacity: 1,
							visibility: "visible",
							duration: 1.5,
						});
					},
					onLeave: () => {
						if (section.id === "faq") {
						} else {
							gsap.to(section, {
								opacity: 0,
								visibility: "hidden",
								duration: 1.5,
							});
						}
					},
					onEnterBack: () => {
						if (prevVisibleSection !== section.id) {
							setVisibleSection(section.id || null);
							setPrevVisibleSection(section.id);
						}
						gsap.to("#scrollDemo", {
							backgroundColor: section.dataset.bgcolor,
							color: section.dataset.textcolor,
							overwrite: "auto",
						});
						gsap.to(section, {
							opacity: 1,
							visibility: "visible",
							duration: 1.5,
						});
					},
					onLeaveBack: () => {
						gsap.to("#scrollDemo", {
							backgroundColor: prevBgColor,
							overwrite: "auto",
						});
						gsap.to(section, {
							opacity: 0,
							visibility: "hidden",
							duration: 1.5,
						});
					},
				},
			});
		});
	}, []);

	const scrollDemoRef = useRef(null);

	const [lastScroll, setLastScroll] = useState<number>(0);

	const [prevVisibleSection, setPrevVisibleSection] = useState<string | null>(
		null
	);
	const [visibleSection, setVisibleSection] = useState<string | null>(null);

	const handleScroll = () => {
		const head = document.getElementById("header");
		const headLines = document.getElementById("headLines");
		const headLinks = document.getElementById("headLinks");

		headLinks?.classList.add("hidden");
		headLinks?.classList.remove("flex");
		headLines?.classList.remove("hidden");

		if (scrollDemoRef.current) {
			const { scrollTop } = scrollDemoRef.current;

			if (scrollTop > lastScroll) {
				head?.classList.remove("scroll-up");
				head?.classList.add("scroll-down");
			} else {
				head?.classList.remove("scroll-down");
				head?.classList.add("scroll-up");
			}

			setLastScroll(scrollTop);
		}
	};

	const { setBgName } = usePopup();

	useEffect(() => {
		setBgName(visibleSection || "");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [visibleSection]);

	useEffect(() => {
		if (location.pathname === "/services/faq") {
		  const faqSection = document.getElementById("faq");
		  if (faqSection && scrollDemoRef.current) {
			gsap.to(scrollDemoRef.current, {
			  scrollTo: { y: faqSection.offsetTop, autoKill: false },
			  duration: 1,
			});
		  }
		}
	  }, [location.pathname]);

	return (
		<div
			id='scrollDemo'
			ref={scrollDemoRef}
			onScroll={handleScroll}
			className='relative flex flex-col justify-start w-full h-screen overflow-x-hidden overflow-y-scroll main'
		>
			<Headers />
			<section id='imagine' className={`section`} data-bgcolor='#ffffff'>
				<div className='relative flex flex-col items-center justify-center overflow-x-visible h widthClass'>
					<div className='w-[1512px] h- flex flex-col items-center justify-center '>
						<ImagineSection isVisible={visibleSection === "imagine"} />
					</div>
				</div>
			</section>
			<section
				id='pinkSections'
				className={`h-[2499px] container relative flex flex-col items-center w-widthClass mx-auto opacity-0`}
				data-bgcolor='#FF0175'
			>
				<Values />
				<ServiceDetails />
			</section>

			<section
				id='newHeights'
				className={`h-[1080px] section`}
				data-bgcolor='#FFD402'
			>
				<div className='h-[1400px] relative flex flex-col items-center justify-center widthClass overflow-x-visible'>
					<div className='w-[1512px] h-[1400px] flex flex-col items-center justify-center '>
						<NewHeights isVisible={visibleSection === "newHeights"} />
					</div>
				</div>
			</section>
			<section id='faq' className={`h-[1080px] section`} data-bgcolor='#FF0175'>
				<div className='relative flex flex-col items-center justify-center overflow-x-visible h-fit widthClass'>
					<div className='w-[1512px] h-fit flex flex-col items-center justify-center '>
						<FAQ />
					</div>
				</div>
			</section>
			<Footer />
		</div>
	);
};

export default Services;
