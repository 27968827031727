import { gsap } from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import { useGSAP } from "@gsap/react";
gsap.registerPlugin(ScrollTrigger);

const ValueRects = () => {
	useGSAP(() => {
		const timeline01 = gsap.timeline({
			scrollTrigger: {
				trigger: "#valuesSection",
				scroller: "#scrollDemo",
				start: "top center",
				end: "center 80%",

				scrub: 7,
			},
		});
		timeline01.fromTo(
			"#r01",

			{
				position: "absolute",
				x: 90,
				y: 70,
				rotate: 10,
			},
			{
				position: "absolute",
				x: 90,
				y: 0,
				rotate: -4.55,
			}
		);
		const timeline02 = gsap.timeline({
			scrollTrigger: {
				trigger: "#valuesSection",
				scroller: "#scrollDemo",
				start: "top center",
				end: "center 80%",

				scrub: 7,
			},
		});
		timeline02.fromTo(
			"#r02",

			{
				position: "absolute",
				x: 145,
				y: 70,
				scale: 1.1,
			
			},
			{
				position: "absolute",
				x: 145,
				y: 135,
				scale: 1,
				rotate: 3.62,
			}
		);
		const timeline03 = gsap.timeline({
			scrollTrigger: {
				trigger: "#valuesSection",
				scroller: "#scrollDemo",
				start: "top center",
				end: "center 90%",

				scrub: 7,
			},
		});
		timeline03.fromTo(
			"#r03",

			{
				position: "absolute",
				x: 100,
				y: 330,
				scale: 0.5,
				rotate: -2.23,
			},
			{
				position: "absolute",
				x: 100,
				y: 260,
				scale: 1,
				rotate: -2.23,
			}
		);
	}, []);
	return (
		<>
			<div className='z-10 w-fit h-fit ' id='r01'>
				<div className='transition-transform duration-300 hover:scale-105'>
					<img
						src='/assets/Mobile/Services/PinkSections/Values/01.svg'
						alt='Data-driven decisions'
					/>
				</div>
			</div>
			<div className='z-10 w-fit h-fit' id='r02'>
				<div className='transition-transform duration-300 hover:scale-105'>
					<img
						src='/assets/Mobile/Services/PinkSections/Values/02.svg'
						alt='User-centered approach'
					/>
				</div>
			</div>

			<div className='z-10 w-fit h-fit' id='r03'>
				<div className='transition-transform duration-300 hover:scale-105'>
					<img
						src='/assets/Mobile/Services/PinkSections/Values/03.svg'
						alt='Tailored strategies'
					/>
				</div>
			</div>
		</>
	);
};

export default ValueRects;
