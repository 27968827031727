import React from "react";

const DesignElements = () => {
	return (
		<>
			<img
				src='/assets/UnleashSection/star-left.svg'
				alt='star left'
				className='absolute top-[385px] -left-[150px] animate-pulse max-laptop:left-[590px] max-laptop:top-[450px] max-laptop:w-[100px]'
			/>
			<img
				src='/assets/UnleashSection/star-right.svg'
				alt='star right'
				className='absolute top-[395px] max-laptop:right-[520px] max-laptop:top-[445px] max-laptop:w-[232px] -right-[250px]  animate-pulse '
			/>
			<img
				src='/assets/UnleashSection/star-tRight.svg'
				alt='star top right'
				className='absolute top-[140px] max-laptop:top-[80px] max-laptop:right-[615px] right-[290px] scale-75 animate-pulse max-laptop:w-[90px]'
			/>
		</>
	);
};

export default DesignElements;
