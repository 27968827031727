import { useCategory } from "contexts/CategoryContext";
import { toastDefaultOptions } from "lib/consts";
import { Category } from "lib/interfaces";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { categoryService } from "services/blog";

const Categories = () => {
	const [categories, setCategories] = useState<Category[]>([]);

	useEffect(() => {
		const fetchData = () =>
			categoryService
				.getCategories()
				.then((response) => {
					if (response?.success) {
						getUniqueCategories(response.data.items);
					} else toast.error(response?.message, toastDefaultOptions);
				})
				.catch((Error) => {
					toast.error(Error, toastDefaultOptions);
				});
		fetchData();
	}, []);

	const getUniqueCategories = (categories: Category[]) => {
		const uniqueCategories = Array.from(
			new Map(categories.map((cat) => [cat.id, cat])).values()
		); // Remove duplicates by `id`
		setCategories(uniqueCategories);
	};

	const { selectedCategory, setSelectedCategory } = useCategory();

	return (
		<div className='flex flex-wrap gap-[1.5%] max-tablet:px-[5vw] w-[78vw] justify-self-center max-w-[1183px] h-fit justify-center items-center font-roboto font-semibold'>
			{categories.map((cat, idx) => (
				<button
					key={cat.id}
					onClick={() => {
						if (selectedCategory === cat.title) {
							setSelectedCategory("");
						} else {
							setSelectedCategory(cat.title);
						}
					}}
					className='max-tablet:mt-2 relative tracking-[0.11em] laptop:w-[120px] laptop:h-[29px] rounded-[13px] text-[8px] mobile-l:text-[10px] laptop:text-[12px] border-[0.73px] border-squeeze-darkBlue uppercase bg-transparent hover:bg-squeeze-pink text-squeeze-darkBlue hover:text-white hover:border-transparent'
				>
					<p className='absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2'>{cat.title}</p>
				</button>
			))}
		</div>
	);
};

export default Categories;
