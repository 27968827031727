import ValueRects from './ValueRects'
import DesignElements from './DesignElements'

const Values = () => {
  return (
    <div className=' relative text-center w-[1512px] h-[936px]' id='valuesSection'>
        <ValueRects />
        <DesignElements />
    </div>
  )
}

export default Values