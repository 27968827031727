import HeadingTextAnimation from "components/HeadingTextAnimation";

const DescText = ({ isVisible }: { isVisible: boolean }) => {
	return (
		<div className='absolute font-smoother top-[300px] left-[380px] font-robotol text-[20px] text-white leading-[114.5%] space-y-[17px]'>
			<div className=''>
				<HeadingTextAnimation
					smallText
					text={"Right from the start, we knew what our vision"}
					isVisible={isVisible}
					idx={"1"}
				/>
				<HeadingTextAnimation
					smallText
					text={"was: Inspire and encourage creative minds to"}
					isVisible={isVisible}
					idx={"1"}
				/>
				<HeadingTextAnimation
					smallText
					text={"break the status quo, fostering a world where"}
					isVisible={isVisible}
					idx={"1"}
				/>
				<HeadingTextAnimation
					smallText
					text={"boundless imagination and transformative"}
					isVisible={isVisible}
					idx={"1"}
				/>
				<HeadingTextAnimation
					smallText
					text={"ideas thrive."}
					isVisible={isVisible}
					idx={"1"}
				/>
			</div>
			<div>
				<HeadingTextAnimation
					smallText
					text={"It was clear as day we had our mission set out"}
					isVisible={isVisible}
					idx={"2"}
				/>
				<HeadingTextAnimation
					smallText
					text={"for ourselves: Empower businesses with data-"}
					isVisible={isVisible}
					idx={"2"}
				/>
				<HeadingTextAnimation
					smallText
					text={"driven marketing strategies and a user-centric"}
					isVisible={isVisible}
					idx={"2"}
				/>
				<HeadingTextAnimation
					smallText
					text={"approach tailored to their specific needs."}
					isVisible={isVisible}
					idx={"2"}
				/>
			</div>
		</div>
	);
};

export default DescText;
