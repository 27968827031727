import { iLeadMagnet } from "lib/interfaces";
import React, { useState } from "react";

interface LeadMagnetProps {
	leadMagnet: iLeadMagnet;
}

const LeadMagnet: React.FC<LeadMagnetProps> = ({ leadMagnet }) => {
	const [email, setEmail] = useState<string>("Email address");
	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setEmail(e.target.value);
	};

	return (
		<div
			className={`w-[80vw] mobile-l:w-[70vw] max-laptop:max-w-[400px] tablet:w-[50vw] laptop:w-[24.7%] max-laptop:my-6 p-5 space-y-[7%] h-fit bg-${leadMagnet.bgColor} text-${leadMagnet.textColor} rounded-[10px]`}
		>
           
			<p className="text-[20px] laptop-l:text-[30px] font-poppinsEb leading-[1]">{leadMagnet.title}</p>

			<div className='flex items-center gap-3 w-full leading-[117.5%] h-full '>
				<input
					type='text'
					value={email}
					className={`font-roboto focus:outline-none text-[15px] px-3 py-2 w-[59%] h-[17%] text-${leadMagnet.bgColor} px-2 rounded-[62px] bg-${leadMagnet.textColor} `}
					onChange={handleChange}
				/>
				<button
					className={`w-[28%]  h-[17%] px-3 py-2 cursor-pointer bg-${leadMagnet.bgColor} text-${leadMagnet.textColor} rounded-[62px] border-${leadMagnet.textColor} flex items-center justify-center font-poppinsSb text-[12px] border-[1.5px] `}
				>
					SUBMIT
				</button>
			</div>
                <p className="font-roboto text-[12.5px] leading-[117.5%]">We will not share your information with anyone.</p>
		</div>
	);
};

export default LeadMagnet;
