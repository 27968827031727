import ChatButton from "components/ChatButton";
import { useState } from "react";
import OpenMobileHeader from "./OpenMobileHeader";
import { Link } from "react-router-dom";

const MobileHeader = () => {
	const [isOpen, setIsOpen] = useState<boolean>(false);

	return (
		<div className='mx-[5vw] w-full flex justify-between items-center h-full z-50 pt-[2vh] min-h-[70px]'>
			<Link to='/' className='w-[20vw] h-full z-[45]'>
				{isOpen ? (
					<img
						src='/assets/mobile-logo-white.svg'
						alt='Squeeze creative agency logo white'
						className=''
					/>
				) : (
					<img
						src='/assets/mobile-logo.svg'
						alt='Squeeze creative agency logo '
						className=''
					/>
				)}
			</Link>
			<div className='flex items-center justify-end flex-grow w-[35vw] h-[100%] gap-2'>
				<ChatButton mobile isOpen={isOpen} />
				<div
					onClick={() => {
						setIsOpen((prev) => !prev);
					}}
					className={`flex items-center z-[45] justify-center rounded-full ${
						isOpen ? "bg-squeeze-yellow" : "bg-squeeze-pink"
					} aspect-[1] h-[21px] mobile-l:h-[24px]`}
				>
					{isOpen ? (
						<img
							src='/assets/Mobile/Header/lines.svg'
							alt='Three black lines'
							className={`absolute`}
							id='headLines'
						/>
					) : (
						<img
							src='/assets/Mobile/Header/lines-white.svg'
							alt='Three white lines'
							className={`absolute`}
							id='headLines'
						/>
					)}
				</div>
			</div>
			{isOpen && <OpenMobileHeader setIsOpen={setIsOpen} />}
		</div>
	);
};

export default MobileHeader;
