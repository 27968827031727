const ServiceLinks = () => {
	return (
		<>
			<div className='absolute top-[330px] left-[305px] z-10 hover:z-30'>
				<img
					src='/assets/ServicesPage/PinkSections/ServiceDetails/ServiceCards/1.svg'
					alt='Graphic design'
					className='transition-transform duration-300 hover:scale-105'
				/>
			</div>
			<div className='absolute top-[480px] left-[795px] z-10 hover:z-30 rotate-[3.91deg]'>
				<img
					src='/assets/ServicesPage/PinkSections/ServiceDetails/ServiceCards/2.svg'
					alt='SEO'
					className='transition-transform duration-300 hover:scale-105'
				/>
			</div>
			<div className='absolute top-[705px] left-[247px] z-10 hover:z-30 -rotate-[2.04deg]'>
				<img
					src='/assets/ServicesPage/PinkSections/ServiceDetails/ServiceCards/3.svg'
					alt='Copywriting and dontent writing'
					className='transition-transform duration-300 hover:scale-105'
				/>
			</div>
			<div className='absolute top-[865px] left-[770px] z-10 hover:z-30 rotate-[0.28deg]'>
				<img
					src='/assets/ServicesPage/PinkSections/ServiceDetails/ServiceCards/4.svg'
					alt='Social Media Marketing'
					className='transition-transform duration-300 hover:scale-105'
				/>
			</div>
		</>
	);
};

export default ServiceLinks;
