import React, { useEffect, useRef, useState } from "react";
import CarouselElement from "./CarouselElement";
import { gsap } from "gsap";
import { data } from "lib/consts";
import { BlogPost } from "lib/interfaces";

const Carousel: React.FC = () => {
  const sliderRefs = useRef<HTMLDivElement[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isTweening, setIsTweening] = useState(false);
  const [featuredData, setFeaturedData] = useState<BlogPost[]>([]);

  useEffect(() => {
    const filteredData = data.filter((post) => post.isFeatured);
    setFeaturedData(filteredData);
  }, []);

  useEffect(() => {
    sliderRefs.current.forEach((slide, index) => {
      gsap.set(slide, {
        xPercent: index > 0 ? 100 : 0,
      });
    });
  }, [featuredData]);

  const gotoSlide = (value: number) => {
    if (isTweening) return;
    setIsTweening(true);

    const nextIndex = (currentIndex + value + featuredData.length) % featuredData.length;
    const currentSlide = sliderRefs.current[currentIndex];
    const nextSlide = sliderRefs.current[nextIndex];
	
    gsap.set(nextSlide, { xPercent: value > 0 ? 100 : -100 });

    gsap.to(currentSlide, {
      xPercent: value > 0 ? -100 : 100,
      onComplete: () => setIsTweening(false),
    });

    gsap.to(nextSlide, { xPercent: 0 });

    setCurrentIndex(nextIndex);
  };

  return (
    <div className="relative tablet:aspect-[2.5] aspect-[2] break:w-[1183px] w-[78vw] rounded-[27px] overflow-clip">
      {featuredData.map((featuredPost, idx) => (
        <div
          key={idx}
          className="absolute w-full max-laptop:h-full"
          ref={(el) => (sliderRefs.current[idx] = el!)}
        >
          <CarouselElement featuredPost={featuredPost} key={featuredPost.id} />
        </div>
      ))}

			<button className='absolute left-[1%] top-1/2 -translate-y-1/2 z-40' onClick={()=>{gotoSlide(-1)}}>
				<img src='/assets/BlogPage/arrowL.svg' alt='Arrow left' />
			</button>
			<button className='absolute right-[1%] top-1/2 -translate-y-1/2 z-40' onClick={()=>{gotoSlide(1)}}>
				<img src='/assets/BlogPage/arrowR.svg' alt='Arrow right' />
			</button>
		</div>
	);
};

export default Carousel;
