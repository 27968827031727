import ContactForm from "./ContactForm";
import ContactInfo from "./ContactInfo";

const MobileFooter = () => {
	return (
		<div className={`h-[580px] relative z-20 w-full flex justify-center items-end bg-squeeze-darkBlue overflow-x-visible `}>
			<div className='w-[250px] h-[600px] flex flex-col jutify-end space-y-[55px] relative '>
			<img src="/assets/Mobile/Footer/arrow.svg" alt="arrow" className="absolute top-[20px] left-[185px] z-30"/>
			<img src="/assets/Mobile/Footer/logo.svg" alt="arrow" className="absolute top-[280px] left-[155px] z-30"/>
				<ContactForm />
				<ContactInfo />
			</div>
		</div>
	);
};

export default MobileFooter;
