import { BlogPost } from "lib/interfaces";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import ContentsTab from "./ContentsTab";
import LeadMagnet from "./LeadMagnet";
import { copyToClipboard } from "lib/utils";
import { useState } from "react";
import { FacebookShareButton, LinkedinShareButton } from "react-share";

const MainSection = ({ post }: { post: BlogPost }) => {
	const currentURL = window.location.href;

	const [shareOpen, setShareOpen] = useState<boolean>(false);
	const [urlCopied, setUrlCopied] = useState<boolean>(false);
	const [shareHovered, setShareHovered] = useState<boolean>(false);

	return (
		<div className='flex max-laptop:flex-col max-laptop:items-center justify-between w-full h-full my-[10vh]'>
			<div className='w-[20.6%] max-laptop:hidden h-full'>
				<ContentsTab content={post.content} />
			</div>
			<div className='w-[80vw] laptop:w-[53.3%] h-full text-squeeze-darkBlue'>
				<ReactQuill value={post.content} readOnly={true} theme='bubble' />

				<div className='flex max-laptop:justify-center text-squeeze-pink font-poppinsSb gap-[5.5%] mt-[5%]'>
					<button
						onClick={() => {
							setShareOpen(true);
						}}
						onMouseEnter={() => {
							setShareHovered(true);
						}}
						onMouseLeave={() => {
							setShareHovered(false);
						}}
						className={`items-center justify-center flex w-[30vw] laptop:w-[15%] hover:text-white hover:bg-squeeze-pink border-[0.93px] leading-[117.5%] py-2 laptop:py-3 rounded-[49px] border-squeeze-pink`}
					>
						{shareOpen ? (
							<div className='flex items-center justify-around px-[20%] w-full'>
								<FacebookShareButton url={currentURL}>
									<img
										src={`/assets/BlogPage/fb-${
											shareHovered ? "white" : "pink"
										}.svg`}
										alt='fb'
										className='h-fit'
									/>
								</FacebookShareButton>
								<LinkedinShareButton url={currentURL}>
									<img
										src={`/assets/BlogPage/linkedin-${
											shareHovered ? "white" : "pink"
										}.svg`}
										alt='linkedIn'
										className='h-fit'
									/>
								</LinkedinShareButton>
							</div>
						) : (
							<p>SHARE</p>
						)}
					</button>
					<button
						onClick={() => {
							copyToClipboard(currentURL);
							setUrlCopied(true);
						}}
						className={`${shareOpen ? "" : "hidden"} ${
							urlCopied
								? "text-xs laptop-l:text-sm"
								: ""
						} items-center justify-center flex w-[30vw] laptop:w-[15%] hover:text-white hover:bg-squeeze-pink border-[0.93px] leading-[117.5%] py-2 laptop:py-3 rounded-[49px] border-squeeze-pink`}
					>
						{urlCopied ? "LINK COPIED" : "COPY LINK"}
					</button>
				</div>
			</div>
			<LeadMagnet
				leadMagnet={{
					id: "1",
					title: "Get a free SEO analysis",
					bgColor: "squeeze-pink",
					textColor: "white",
				}}
			/>
		</div>
	);
};

export default MainSection;
