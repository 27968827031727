
import Eyes from "./Eyes";
import EyeSectionText from "./EyeSectionText";

const EyeSection = ({ isVisible }: { isVisible: boolean }) => {
	return (
		<div className='h-[1172px] relative flex flex-col items-center justify-center widthClass overflow-x-clip'>
			<div className='widthClass h-[1172px]  '>
				<div className='relative text-white h- widthClass '>
					<EyeSectionText isVisible={isVisible}/>
					<Eyes />
				</div>
			</div>
		</div>
	);
};

export default EyeSection;
