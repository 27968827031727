import Marquee from "components/Marquee";

import AllAboutYou from "./AllAboutYou";
import AnimatedDesc from "./AnimatedDesc";

const YouSection = ({ isVisible }: { isVisible: boolean }) => {
	return (
		<div className='h-[1065px] relative flex flex-col items-center justify-center widthClass '>
			<div className='w-[1512px] h-[1065px] flex items-center justify-center '>
				<div className='w-[960px] h-full relative overflow-clip'>
					<AllAboutYou />
					<AnimatedDesc isVisible={isVisible} />
				</div>
				<div className='absolute -rotate-[7deg] -top-[150px] '>
					<Marquee trigger={"+"} />
				</div>
				<div className='absolute -rotate-[7deg] -bottom-[90px]'>
					<Marquee trigger={"-"} />
				</div>
			</div>
		</div>
	);
};

export default YouSection;
