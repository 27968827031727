import { Heading } from "lib/interfaces";
import { extractHeadings } from "lib/utils";
import { useState, useEffect } from "react";

const ContentsTab: React.FC<{ content: string }> = ({ content }) => {
    const [headings, setHeadings] = useState<Heading[]>([]);
  
    useEffect(() => {
       
        const headingsArray = extractHeadings(content);
        setHeadings(headingsArray); 
      }, [content]);
    
      return (
        <div className="text-squeeze-darkBlue space-y-[5%] ">
          <p className="font-robotoB leading-[129%] text-[12px] lapotp-l:text-[16px] uppercase">Contents</p>
          <div className="font-roboto text-[11px] laptop-l:text-[15px] ">
            {headings.map((heading) => (
              <div
                key={heading.id}
                className={`level-${heading.level} w-[80%]`}
                style={{
                  paddingLeft: `${heading.level - 1}rem`, // Adjusting indentation based on level
                }}

              >
                <p className="">{heading.text}</p>
              </div>
            ))}
          </div>
        </div>
      );
    };
  
  export default ContentsTab;