import { BlogPost } from "lib/interfaces";
import { copyToClipboard, formatDate } from "lib/utils";
import { useState } from "react";
import { FacebookShareButton, LinkedinShareButton } from "react-share";

const HeadingSection = ({ post }: { post: BlogPost }) => {
	const currentURL = window.location.href;
	const [shareOpen, setShareOpen] = useState<boolean>(false);
	const [urlCopied, setUrlCopied] = useState<boolean>(false);
	const [shareHovered, setShareHovered] = useState<boolean>(false);

	const apiUrl = `${process.env.REACT_APP_API_URL}`;
	return (
		<div className='flex max-laptop:flex-col items-center w-full h-fit gap-[4vh] laptop:gap-[4%]'>
			<div className='w-[80vw] laptop:w-[50.5%] h-fit aspect-[1.5] '>
				<img
					src={
						post.thumbnail && post.thumbnail !== ""
							? `${apiUrl}${post.thumbnail}`
							: "/assets/temp/bullseye.png"
					}
					alt='Blog cover'
					className='object-cover w-full h-full rounded-[27px]'
				/>
			</div>
			<div className='w-[80vw] laptop:w-[33%] space-y-[5%]'>
				<div>
					<p className='uppercase font-robotoB text-sm laptop:text-[16px] leading-[129%] tracking-[0.11rem] text-squeeze-pink'>
						{post.category.title}
					</p>
					<p className='font-poppinsEb text-3xl laptop:text-5xl leading-[112%] -tracking-[0.015rem] text-squeeze-darkBlue'>
						{post.title}
					</p>
				</div>
				<div className='flex items-center h-fit text-[13px] laptop:text-[20px] leading-[129%] text-squeeze-darkBlue mt-[5%]'>
					<p className='font-roboto '>by {post.author.name}</p>
					<p className='text-base text-squeeze-darkBlue mx-[2%]'>|</p>
					<p className='font-robotol'>{formatDate(post.createdAt)}</p>
				</div>
				<div className='flex text-squeeze-pink font-poppinsSb gap-[5.5%] text-sm laptop-l:text-base'>
					<button
						onClick={() => {
							setShareOpen(true);
						}}
						onMouseEnter={() => {
							setShareHovered(true);
						}}
						onMouseLeave={() => {
							setShareHovered(false);
						}}
						className={`items-center justify-center flex w-[50%] laptop:w-[29%] hover:text-white hover:bg-squeeze-pink border-[0.93px] leading-[117.5%] py-2 laptop:py-3 rounded-[49px] border-squeeze-pink`}
					>
						{shareOpen ? (
							<div className='flex items-center justify-around px-[20%] w-full'>
								<FacebookShareButton url={currentURL}>
									<img
										src={`/assets/BlogPage/fb-${
											shareHovered ? "white" : "pink"
										}.svg`}
										alt='fb'
										className='h-fit'
									/>
								</FacebookShareButton>
								<LinkedinShareButton url={currentURL}>
									<img
										src={`/assets/BlogPage/linkedin-${
											shareHovered ? "white" : "pink"
										}.svg`}
										alt='linkedIn'
										className='h-fit'
									/>
								</LinkedinShareButton>
							</div>
						) : (
							<p>SHARE</p>
						)}
					</button>
					<button
						onClick={() => {
							copyToClipboard(currentURL);
							setUrlCopied(true);
						}}
						className={`${shareOpen ? "" : "hidden"} ${
							urlCopied ? "text-xs laptop-l:text-sm" : ""
						} items-center justify-center flex w-[50%] laptop:w-[29%] hover:text-white hover:bg-squeeze-pink border-[0.93px] leading-[117.5%] py-2 laptop:py-3 rounded-[49px] border-squeeze-pink`}
					>
						{urlCopied ? "LINK COPIED" : "COPY LINK"}
					</button>
				</div>
			</div>
		</div>
	);
};

export default HeadingSection;
