import React, {
    createContext,
    ReactNode,
    useContext,
    useState
} from "react";

interface CategoryContextType {
	selectedCategory: string;
	setSelectedCategory: React.Dispatch<React.SetStateAction<string>>;
}
const CategoryContext = createContext<CategoryContextType | undefined>(
	undefined
);
export const CategoryProvider: React.FC<{ children: ReactNode }> = ({
	children,
}) => {
	const [selectedCategory, setSelectedCategory] = useState<string>("");

	return (
		<CategoryContext.Provider
			value={{
				selectedCategory,
				setSelectedCategory,
			}}
		>
			{children}
		</CategoryContext.Provider>
	);
};

export const useCategory = (): CategoryContextType => {
	const context = useContext(CategoryContext);
	if (context === undefined) {
		throw new Error("useCategory must be used within a CategoryProvider");
	}
	return context;
};
