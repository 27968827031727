import { usePopup } from "contexts/PopupContext";
import { copyToClipboard, toTop } from "lib/utils";

import { Link, useLocation } from "react-router-dom";

const Footer = () => {
	const location = useLocation();
	const { setPopupOpen } = usePopup();

	return (
		<div
			className={`max-laptop:hidden flex justify-center bg-footer-pattern relative overflow-clip z-50 ${
				location.pathname === "/blog" ? "h-[1150px]" : "h-[1000px]"
			} w-full pt-[180px] z-20 min-h-[1000px] footer text-white`}
		>
			<div className='h-[470px] justify-self-center self-end w-[1184px] font-robotol relative max-laptop-l:mx-[5vw]'>
				<img
					src='/assets/Footer/logo.svg'
					alt='Squeeze creative logo'
					className='absolute -bottom-[175px] right-0 laptop-l:-right-[50px] rotate-[19.42deg]'
				/>

				<div className='absolute  bottom-[50px] left-0 flex flex-col'>
					<div className='-space-y-[25px] mb-[15px]'>
						<p className='font-poppinsL text-[47px]'>Interested?</p>
						<button
							className='font-poppinsEb underline underline-offset-8 decoration-[3px] text-[72px] text-squeeze-yellow h-fit'
							onClick={() => {
								setPopupOpen(true);
							}}
						>
							Let's chat!
						</button>
					</div>
					<div className='text-[20px] h-fit mb-[35px]'>
						<p
							onClick={() => copyToClipboard("squeezecreative@gmail.com")}
							className='cursor-pointer'
						>
							squeezecreative@gmail.com
						</p>
						<p
							onClick={() => copyToClipboard("+389 78 232 232")}
							className='cursor-pointer'
						>
							+389 78 232 232
						</p>
					</div>
					<a
						href='https://amalasolutions.com'
						target='_blank'
						rel='noopener noreferrer'
						className='flex  cursor-pointer justify-center items-center border w-[260px] h-[70px] border-squeeze-yellow rounded-xl text-[18px]'
					>
						<div>
							<span>Like our website?</span>
							<div>
								<span className='font-robotom'>Amala Solutions</span>
								<span> developed it!</span>
							</div>
						</div>
					</a>
				</div>
				<div className='absolute top-[45px] right-0 flex grid-rows-3 gap-[35px] font-roboto text-[20px] leading-[74%]'>
					<div className='flex flex-col gap-[20px]'>
						<Link
							onClick={() => {
								toTop();
							}}
							to='/'
						>
							home
						</Link>
						<Link
							onClick={() => {
								toTop();
							}}
							to='/services'
						>
							services
						</Link>
						<Link
							onClick={() => {
								toTop();
							}}
							to='/blog'
						>
							blog
						</Link>
					</div>
					<div className='flex flex-col gap-[20px]'>
						<a
							target='_blank'
							rel='noopener noreferrer'
							href='https://www.instagram.com/squeezecreativeagency/?hl=en'
						>
							Instagram
						</a>
						<a
							target='_blank'
							rel='noopener noreferrer'
							href='https://www.linkedin.com/company/squeeze-creative-agency/?trk=public_post_feed-actor-name'
						>
							LinkedIn
						</a>
						<a
							target='_blank'
							rel='noopener noreferrer'
							href='https://www.facebook.com/profile.php?id=100095734291715&mibextid=ZbWKwL'
						>
							Facebook
						</a>
					</div>
				</div>
				<img
					src='/assets/Footer/arrow.svg'
					alt='Yellow arrow'
					className='absolute top-[55px] left-[420px]'
				/>
			</div>
		</div>
	);
};

export default Footer;
