import Footer from "../Footer";
import MobileFooter from "../Mobile/MobileFooter";
import MobileNewsletter from "../Mobile/MobileNewsletter";
import NewsletterApplicaiton from "./NewsletterApplicaiton";
import YellowFooter from "./YellowFooter";

const BlogFooters = () => {
	return (
		<>
			<MobileNewsletter />
			<div className='max-laptop:hidden'>
				<NewsletterApplicaiton />
				<YellowFooter />
			</div>
			<Footer />
			<div className='relative w-full overflow-x-clip laptop:hidden bg-squeeze-yellow'>
				<img
					src='/assets/Mobile/Footer/wiggle.svg'
					alt='Background'
					className='w-[1022px]'
				/>
				<MobileFooter />
			</div>
		</>
	);
};

export default BlogFooters;
