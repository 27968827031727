import React from "react";

const MobileAllAboutYou = () => {
	return (
		<>
			<img
				src='/assets/Mobile/Home/YouSection/star.svg'
				alt='Yellow star'
				className='absolute top-[85px] left-[110px]'
			/>

			<p className='absolute top-[1px] text-white font-poppinsB text-[49.35px] -rotate-[7deg] uppercase'>
				we’re all{" "}
			</p>
			<p className='absolute top-[45px] left-[170px] text-white font-poppinsB text-[49.35px] -rotate-[7deg] uppercase'>
				about
			</p>
			<img
				src='/assets/Mobile/Home/YouSection/yellow-you.svg'
				alt="Yellow 'you'"
				className='absolute top-[105px] left-[135px] z-10'
			/>
		</>
	);
};

export default MobileAllAboutYou;
