import MobileAccordionItem from "./MobileAccordionItem";

const MobileAccordionItems = () => {
	return (
		<div className='absolute top-[555px] left-[50%] -translate-x-1/2  space-y-[8px] z-10'>
			<MobileAccordionItem title='Reach out & share your needs' number={"01"}>
				<p>We are available 24/7 to discuss</p>
				<p>your business's unique needs and</p>
				<p>personal requirements.</p>
			</MobileAccordionItem>
			<MobileAccordionItem title='Get a tailored strategy' number={"02"}>
				<p>Based on your needs, our expert team</p>
				<p>will develop a bespoke strategy using</p>
				<p>user-centred methods & data-driven</p>
				<p>decision.</p>
			</MobileAccordionItem>
			<MobileAccordionItem title='Strategies in motion' number={"03"}>
				<p>Watch your business thrive through</p>
				<p>the performance reports we deliver</p>
				<p>to ensure full transparency.</p>
			</MobileAccordionItem>
		</div>
	);
};

export default MobileAccordionItems;
