import { useGSAP } from "@gsap/react";
import MobileFooter from "components/Footer/Mobile/MobileFooter";
import MobileHeader from "components/Header/MobileHeaders/MobileHeader";
import ClosingSection from "components/Home/MobileComponents/ClosingSection/ClosingSection";
import MobileEyeSection from "components/Home/MobileComponents/EyeSection/MobileEyeSection";
import MobileGetThere from "components/Home/MobileComponents/GetThereSection/MobileGetThere";
import MobileGoodBad from "components/Home/MobileComponents/PinkSections/GoodBadSection/MobileGoodBad";
import MobileParallaxElements from "components/Home/MobileComponents/PinkSections/MobileParallaxElements";
import MobilePinkSections from "components/Home/MobileComponents/PinkSections/MobilePinkSections";
import MobileServicesSection from "components/Home/MobileComponents/ServicesSection/MobileServicesSection";
import MobileYouSection from "components/Home/MobileComponents/YouSection/MobileYouSeciton";
import UnleashSection from "components/Home/UnleashSection/UnleashSection";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import { useRef, useState } from "react";
gsap.registerPlugin(ScrollTrigger);

const MobileHome = () => {
	useGSAP(() => {
		const sections = document.querySelectorAll<HTMLElement>("[data-bgcolor]");
		if (visibleSection === null) {
			setVisibleSection("unleash");
			setPrevVisibleSection("unleash");
		}
		sections.forEach((section, i) => {
			const prevBgColor =
				i === 0 ? "#ffffff" : sections[i - 1].dataset.bgcolor || "";

			gsap.timeline({
				scrollTrigger: {
					trigger: section,
					scroller: "#scrollDemo",
					start: "top 50%",
					end: "bottom 50%",
					onEnter: () => {
						if (prevVisibleSection !== section.id) {
							setVisibleSection(section.id || null);
							setPrevVisibleSection(section.id);
						}
						gsap.to("#scrollDemo", {
							backgroundColor: section.dataset.bgcolor,
							color: section.dataset.textcolor,
							overwrite: "auto",
						});
						gsap.to(section, {
							opacity: 1,
							visibility: "visible",
							duration: 1.5,
						});
					},
					onLeave: () => {
						if (section.id === "closingSection") {
						} else {
							gsap.to(section, {
								opacity: 0,
								visibility: "hidden",
								duration: 1.5,
							});
						}
					},
					onEnterBack: () => {
						if (prevVisibleSection !== section.id) {
							setVisibleSection(section.id || null);
							setPrevVisibleSection(section.id);
						}
						gsap.to("#scrollDemo", {
							backgroundColor: section.dataset.bgcolor,
							color: section.dataset.textcolor,
							overwrite: "auto",
						});
						gsap.to(section, {
							opacity: 1,
							visibility: "visible",
							duration: 1.5,
						});
					},
					onLeaveBack: () => {
						gsap.to("#scrollDemo", {
							backgroundColor: prevBgColor,
							overwrite: "auto",
						});
						gsap.to(section, {
							opacity: 0,
							visibility: "hidden",
							duration: 1.5,
						});
					},
				},
			});
		});
	}, []);

	const scrollDemoRef = useRef(null);

	const [prevVisibleSection, setPrevVisibleSection] = useState<string | null>(
		null
	);
	const [visibleSection, setVisibleSection] = useState<string | null>(null);

	const [trustFlag, setTrustFlag] = useState<boolean>(false);

	useGSAP(() => {
		gsap.timeline({
			scrollTrigger: {
				trigger: "#trustSection",
				scroller: "#scrollDemo",
				start: "top center",
				end: "130% center",
				onEnter: () => {
					setTrustFlag(true);
				},
				onEnterBack: () => {
					setTrustFlag(true);
				},
				onLeave: () => {
					setTrustFlag(false);
				},
				onLeaveBack: () => {
					setTrustFlag(false);
				},
			},
		});
	}, []);

	useGSAP(() => {
		const timelineCsRectTR = gsap.timeline({
			scrollTrigger: {
				trigger: "#closingCont",
				scroller: "#scrollDemo",
				start: "top center",
				end: "center center",

				scrub: 2,
			},
		});
		timelineCsRectTR.fromTo(
			"#csRectTR",
			{ x: 1130, y: 50, scale: 1.5 },
			{ x: 1130, y: 120, scale: 1 }
		);
		const timelineCsRectMr = gsap.timeline({
			scrollTrigger: {
				trigger: "#closingCont",
				scroller: "#scrollDemo",
				start: "top center",
				end: "center center",

				scrub: 3,
			},
		});
		timelineCsRectMr.fromTo(
			"#csRectMR",
			{ x: 995, y: 500, scale: 0.5 },
			{ x: 995, y: 400, scale: 1 }
		);
		const timelineCsRectBr = gsap.timeline({
			scrollTrigger: {
				trigger: "#closingCont",
				scroller: "#scrollDemo",
				start: "top center",
				end: "center center",

				scrub: 3,
			},
		});
		timelineCsRectBr.fromTo(
			"#csRectBR",
			{ x: 1330, y: 200, rotate: 20 },
			{ x: 1330, y: 80, rotate: 0 }
		);
		const timelineCsRectTL = gsap.timeline({
			scrollTrigger: {
				trigger: "#closingCont",
				scroller: "#scrollDemo",
				start: "top center",
				end: "center center",

				scrub: 6,
			},
		});
		timelineCsRectTL.fromTo(
			"#csRectL",
			{ x: -150, y: 430, rotate: 20 },
			{ x: -150, y: 50, rotate: 0 }
		);
	}, []);

	const handleScroll = () => {
		const headLines = document.getElementById("headLines");
		const headLinks = document.getElementById("headLinks");

		if (scrollDemoRef.current) {
			headLinks?.classList.add("hidden");
			headLinks?.classList.remove("flex");
			headLines?.classList.remove("hidden");
		}
	};
	return (
		<div
			id='scrollDemo'
			ref={scrollDemoRef}
			onScroll={handleScroll}
			className='relative flex flex-col items-center justify-start w-full h-screen overflow-x-hidden overflow-y-scroll laptop:hidden main'
		>
			<div className=' fixed top-0 z-40 flex items-center w-full bg-white h-fit py-[10px]  '>
				<MobileHeader />
			</div>
			<section id='unleash' className={` section`} data-bgcolor='#ffffff'>
				<div className='relative flex flex-col items-center justify-center overflow-x-visible widthClass'>
					<div className='w-[1512px]  flex flex-col items-center justify-center '>
						<UnleashSection isVisible={visibleSection === "unleash"} />
					</div>
				</div>
			</section>
			<section
				id='cardsTrustGoodBad'
				className={`h-[2499px] w-[500px] container relative flex flex-col items-center widthClass  opacity-0`}
				data-bgcolor='#FF0175'
			>
				<MobileParallaxElements />
				<MobilePinkSections trustFlag={trustFlag}/>
				<MobileGoodBad />
			</section>
			<section
				id='getThere'
				className={`h-[1080px] section`}
				data-bgcolor='#FFDD00'
			>
				<MobileGetThere isVisible={visibleSection === "getThere"} />
			</section>
			<section
				id='youSection'
				className={`h-[850px] section`}
				data-bgcolor='#0C1B31'
			>
				<MobileYouSection isVisible={visibleSection === "youSection"} />
			</section>
			<section className={`h-[1290px] section`} data-bgcolor='#FFDD00'>
				<MobileServicesSection />
			</section>
			<section
				id='eyeSection'
				className={`
            h-[650px] section`}
				data-bgcolor='#0C1B31'
			>
				<MobileEyeSection isVisible={visibleSection === "eyeSection"} />
			</section>
			<section
				id='closingSection'
				className={`h-[1150px] section`}
				data-bgcolor='#FF0175'
			>
				<div className='h-[720px] relative flex flex-col items-center justify-center w-[450px] overflow-x-visible'>
					<div className='w-[450px] h-[720px] realtive' id='closingCont'>
						<ClosingSection isVisible={visibleSection === "closingSection"} />
					</div>
				</div>
			</section>

			<div className='relative w-[1022px] '>
				<img
					src='/assets/Mobile/Footer/wiggle.svg'
					alt='Background'
					className='-mb-[50px] w-[1022px]'
				/>
				<MobileFooter />
			</div>
		</div>
	);
};

export default MobileHome;
