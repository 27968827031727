import { useGSAP } from "@gsap/react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import GBParallaxElements from "./GBParallaxElements";

gsap.registerPlugin(ScrollTrigger);

const MobileGoodBad = () => {
	useGSAP(() => {
		const flipIn = gsap.timeline({ paused: true });
		flipIn.to("#card", { rotationY: 180, duration: 0.2, ease: "power2.inOut" });

		const flipOut = gsap.timeline({ paused: true });
		flipOut.to("#card", { rotationY: 0, duration: 0.2, ease: "power2.inOut" });

		ScrollTrigger.create({
			trigger: "#gbSection",
			scroller: "#scrollDemo",
			start: "top center",
			onEnter: () => flipIn.play(0),
			onEnterBack: () => flipIn.play(0),
			onLeave: () => flipOut.play(0),
			onLeaveBack: () => flipOut.play(0),
		});
	}, []);

	useGSAP(() => {
		const flipIn2 = gsap.timeline({ paused: true });
		flipIn2.to("#card2", {
			rotationY: 180,
			duration: 0.2,
			ease: "power2.inOut",
		});

		const flipOut2 = gsap.timeline({ paused: true });
		flipOut2.to("#card2", {
			rotationY: 0,
			duration: 0.2,
			ease: "power2.inOut",
		});

		ScrollTrigger.create({
			trigger: "#gbSection",
			scroller: "#scrollDemo",
			start: "top center",
			onEnter: () => flipIn2.play(0),
			onEnterBack: () => flipIn2.play(0),
			onLeave: () => flipOut2.play(0),
			onLeaveBack: () => flipOut2.play(0),
		});
	}, []);
	return (
		<div className='h-[833px] relative w-[440px] ' id='gbSection'>
			<GBParallaxElements />
			<div
				id='card'
				className='-rotate-[3.5deg] absolute top-[0px] left-[150px] w-[168.04px] h-[208.6px] transform-style-3d '
			>
				<div
					className='absolute w-[168.04px] h-[208.6px] backface-hidden'
					id='goodFront'
				>
					<img
						src='/assets/Mobile/Home/PinkSections/good-front.svg'
						alt='Front'
						className='absolute top-0 left-0 w-[168.04px] h-[208.6px]'
					/>
				</div>
				<div
					className='absolute w-[168.04px] h-[208.6px] backface-hidden rotate-y-180'
					id='goodBack'
				>
					<img
						src='/assets/Mobile/Home/PinkSections/good-back.svg'
						alt='Back'
						className='absolute top-0 left-0 w-[168.04px] h-[208.6px]'
					/>
				</div>
			</div>
			<div
				id='card2'
				className='rotate-[3.93deg] absolute top-[250px] left-[120px] w-[168.04px] h-[208.67px] transform-style-3d '
			>
				<div
					className='absolute w-[168.04px] h-[208.6px] backface-hidden'
					id='badFront'
				>
					<img
						src='/assets/Mobile/Home/PinkSections/bad-front.svg'
						alt='bad Front'
						className='absolute top-0 left-0 w-[168.04px] h-[208.6px]'
					/>
				</div>
				<div
					className='absolute w-[168.04px] h-[208.6px] backface-hidden rotate-y-180'
					id='badBack'
				>
					<img
						src='/assets/Mobile/Home/PinkSections/bad-back.svg'
						alt='bad Back'
						className='absolute top-0 left-0 w-[168.04px] h-[208.6px]'
					/>
				</div>
			</div>
		</div>
	);
};

export default MobileGoodBad;
