import HeadingTextAnimation from 'components/HeadingTextAnimation'
import React from 'react'

const TextElements = ({isVisible}:{isVisible:boolean}) => {
  return (
   <>
   <div className='w-fit text-squeeze-darkBlue font-poppinsB text-[40px] leading-[100%]'>
					<HeadingTextAnimation
						idx={"1"}
						isVisible={isVisible}
						text='How are we'
						textClass='min-h-[46px]'
					/>

					<HeadingTextAnimation
						idx={"2"}
						isVisible={isVisible}
						text='going to get'
						textClass='min-h-[46px]'
					/>

					<HeadingTextAnimation
						idx={"3"}
						isVisible={isVisible}
						text='you'
						textClass='min-h-[46px]'
					/>
				</div>
				<div className='absolute top-[452px] left-[190px] font-poppinsBli text-[47.74px] leading-[106%] text-squeeze-pink lowercase -rotate-[4deg]'>
					<HeadingTextAnimation
						idx={"3"}
						isVisible={isVisible}
						text='There'
						extraClass='min-w-[110%]'
					/>
				</div>
				<div className='absolute top-[448px] left-[325px] font-poppinsB text-[47.74px] leading-[106%] text-squeeze-darkBlue lowercase -rotate-[4deg]'>
					<HeadingTextAnimation
						idx={"3"}
						isVisible={isVisible}
						text='?'
						extraClass='min-w-[110%]'
					/>
				</div></>
  )
}

export default TextElements