import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import ScrollTrigger from "gsap/dist/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const ParallaxElements = () => {
	useGSAP(() => {
		const timelineStars = gsap.timeline({
			scrollTrigger: {
				trigger: "#yellowStars",
				scroller: "#scrollDemo",
				start: "top center",
				scrub: 5,
			},
		});
		timelineStars.fromTo("#yellowStars", { x: 480, y: 675 }, { x: 425, y: 1200 });

		const timelineBirds = gsap.timeline({
			scrollTrigger: {
				trigger: "#yellowBirds",
				scroller: "#scrollDemo",
				start: "top top",
				scrub: 6,
			},
		});
		timelineBirds.fromTo(
			"#yellowBirds",
			{ x: -520, y: 290 },
			{ x: -460, y: 1250 }
		);
	}, []);
	return (
		<>
			<img
				src='/assets/QCSection/yellow-birds.svg'
				id='yellowBirds'
				alt='yellow birds'
				className='z-30 abasolute'
			/>
			<img
				src='/assets/QCSection/yellow-stars.svg'
				id='yellowStars'
				alt='Yellow stars'
				className='absolute'
			/>
		</>
	);
};

export default ParallaxElements;
