import Carousel from "components/Blog/CarouselSection/Carousel";
import Categories from "components/Blog/Categories";
import PostGrid from "components/Blog/Posts/PostGrid";
import BlogFooters from "components/Footer/Blog/BlogFooters";
import BlogHeaders from "components/Header/BlogHeaders";
import { useCategory } from "contexts/CategoryContext";
import { toastDefaultOptions } from "lib/consts";
import { BlogPost } from "lib/interfaces";
import { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { blogService } from "services/blog";

const Blog = () => {
	const [lastScroll, setLastScroll] = useState<number>(0);
	const [posts, setPosts] = useState<BlogPost[]>([]);

	useEffect(() => {
		const fetchData = () =>
			blogService
				.getPosts()
				.then((response) => {
					if (response?.success) {
						setPosts(response.data.items);
					} else toast.error(response?.message, toastDefaultOptions);
				})
				.catch((Error) => {
					toast.error(Error, toastDefaultOptions);
				});
		fetchData();
	}, []);

	const { selectedCategory } = useCategory();
	useEffect(() => {
		if (selectedCategory !== "") {
			const filteredPosts: BlogPost[] = posts.filter(
				(post) => post.category.title === selectedCategory
			);
			setPosts(filteredPosts);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedCategory]);

	const handleScroll = () => {
		const head = document.getElementById("header");
		const scrollTop = window.scrollY;
		const headLines = document.getElementById("headLines");
		const headLinks = document.getElementById("headLinks");

		headLinks?.classList.add("hidden");
		headLinks?.classList.remove("flex");
		headLines?.classList.remove("hidden");

		if (scrollTop > lastScroll) {
			head?.classList.remove("scroll-up");
			head?.classList.add("scroll-down");
		} else {
			head?.classList.remove("scroll-down");
			head?.classList.add("scroll-up");
		}
		setLastScroll(scrollTop);
	};

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lastScroll]);

	return (
		<div
			className='relative flex flex-col items-center justify-center w-full overflow-visible h-fit overflow-x-clip'
			id='scrollDemo'
		>
			<BlogHeaders />
			<div className='break:w-[1183px] max-break:px-[10vw] w-full laptop:h-full h-fit tablet:mt-[150px] mt-[12vh] space-y-[5vh]'>
				<div className='relative flex items-center justify-center w-full h-fit'>
					<Carousel />
				</div>

				<Categories />
				<PostGrid posts={posts} />
			</div>

			<BlogFooters />
			<ToastContainer />
		</div>
	);
};

export default Blog;
