import { toastDefaultOptions } from "lib/consts";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { contactService } from "services/contact";

const ContactForm = ({ bgTrigger }: { bgTrigger: boolean }) => {
	const [name, setName] = useState<string>("");
	const [email, setEmail] = useState<string>("");
	const [message, setMessage] = useState<string>("");
	const [isSendingMessage, setIsSendingMessage] = useState<boolean>(false);

	const validateEmail = (email: string) => {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailRegex.test(email);
	};

	const handleSubmit = () => {
		if (!name || !email || !message) {
			toast.error("Please fill all the information.", toastDefaultOptions);
			return;
		}

		if (!validateEmail(email)) {
			toast.error("Please enter a valid email address.", toastDefaultOptions);
			return;
		}

		setIsSendingMessage(true);

		contactService
			.sendMessage(name, email, message)
			.then((response) => {
				if (response.isFailed) {
					toast.error(
						"An error occured while sending the message",
						toastDefaultOptions
					);
				} else {
					toast.success("Message sent!", toastDefaultOptions);
				}
			})
			.catch(() => {
				toast.error(
					"An error has occured while sending the message.",
					toastDefaultOptions
				);
			})
			.finally(() => {
				setIsSendingMessage(false);
			});
	};

	return (
		<form className='relative flex flex-col w-[17vw] p h-fit text-white space-y-[21px] text-[20px] '>
			<input
				required
				onChange={({ target: { value } }) => setName(value)}
				value={name}
				type='text'
				className='w-full focus:outline-none border-b-[1px] border-white h-[35px] placeholder-white bg-transparent'
				placeholder='Name'
			/>
			<input
				required
				onChange={({ target: { value } }) => setEmail(value)}
				value={email}
				type='email'
				className='w-full focus:outline-none border-b-[1px] border-white h-[35px] placeholder-white bg-transparent'
				placeholder='E-mail adress'
			/>
			<textarea
				required
				onChange={({ target: { value } }) => setMessage(value)}
				value={message}
				className='w-full resize-none text-justify-start border-b-[1px] outline-none border-white h-[115px] placeholder-white bg-transparent'
				placeholder='Message'
			/>
			<button
				onClick={handleSubmit}
				type='submit'
				disabled={isSendingMessage}
				className={`self-center uppercase flex items-center justify-center rounded-[63.65px] bg-squeeze-yellow ${
					bgTrigger ? "text-squeeze-pink" : "text-squeeze-darkBlue"
				} w-[129px] h-[45px] text-[17px] font-poppinsSb`}
			>
				Submit
			</button>
		</form>
	);
};

export default ContactForm;
