import { Link } from "react-router-dom";
import DesignElements from "./DesignElements";
import TextElements from "./TextElements";

const UnleashSection = ({ isVisible }: { isVisible: boolean }) => {
	return (
		<div className='relative flex flex-col items-center justify-center widthClass h-fit'>
			<div className='w-full max-laptop:hidden h-[140px]'></div>
			<DesignElements />
			<div className='relative h-[681px] w-full flex justify-center items-center'>
				<div
					className={`space-y-[25px] laptop:space-y-[35px] text-[#0C1B31] flex flex-col justify-center items-center`}
				>
					<TextElements isVisible={isVisible} />
					<Link
						to='/services'
						className={`uppercase  flex items-center justify-center rounded-full bg-squeeze-white border-2 hover:bg-squeeze-pink hover:text-white border-squeeze-pink w-[118px] laptop:w-[197px]  h-[38px] laptop:h-[64px] text-[16px] laptop:text-[27px] font-poppinsSb text-squeeze-pink `}
					>
						let's go
					</Link>
				</div>
			</div>
		</div>
	);
};

export default UnleashSection;
