import { iResponse } from "lib/interfaces";

const blogApiUrl = `${process.env.REACT_APP_API_URL}/blog`;
const categoryApiUrl = `${process.env.REACT_APP_API_URL}/category`;

interface IBlogService {
	getPosts: () => Promise<iResponse>;
	getPost: (id: string) => Promise<iResponse>;
}

interface ICategoryService {
	getCategories: () => Promise<iResponse>;
}

export const blogService: IBlogService = {
	async getPost(id: string) {
		try {
			const response = await fetch(`${blogApiUrl}/${id}`, {
				method: "GET",
			});
			const result: iResponse = await response.json();

			return Promise.resolve(result);
		} catch (error) {
			return Promise.reject(error);
		}
	},
	async getPosts() {
		try {
			const response = await fetch(blogApiUrl, {
				method: "GET",
			});
			const result: iResponse = await response.json();

			return Promise.resolve(result);
		} catch (error) {
			return Promise.reject(error);
		}
	},
};

export const categoryService: ICategoryService = {
	async getCategories() {
		try {
			const response = await fetch(categoryApiUrl, {
				method: "GET",
			});
			const result: iResponse = await response.json();

			return Promise.resolve(result);
		} catch (error) {
			return Promise.reject(error);
		}
	},
};
