import { useRef, useState } from "react";
import { gsap } from "gsap";
import DesignElements from "./DesignElements";

const GoodBad = () => {
	const [isFlipped1, setIsFlipped1] = useState<boolean>(false);
	const [hovered, setHovered] = useState<boolean>(false); // Track if the mouse is inside
	const frontRef1 = useRef<HTMLDivElement>(null);
	const isAnimating = useRef(false); // Track if animation is ongoing
	const pendingExit = useRef(false); // Track if exit animation should trigger after "enter" completes

	const flipCard1 = (flipToFront: boolean) => {
		// If an animation is ongoing, mark pendingExit if we want to flip to front
		if (isAnimating.current) {
			if (flipToFront) {
				pendingExit.current = true;
			}
			return;
		}

		isAnimating.current = true;
		const flipDuration = 0.6;

		gsap.to(frontRef1.current, {
			rotationY: 90,
			duration: flipDuration / 2,
			ease: "power2.in",
			onComplete: () => {
				setIsFlipped1(!flipToFront);

				gsap.to(frontRef1.current, {
					rotationY: 0,
					duration: flipDuration / 2,
					ease: "power2.out",
					onComplete: () => {
						isAnimating.current = false; // Reset animation state

						// If an exit is pending, immediately trigger it
						if (pendingExit.current) {
							pendingExit.current = false;
							flipCard1(true); // Flip to front
						} else if (hovered !== isFlipped1) {
							// Otherwise, check if the mouse state has changed during the animation
							flipCard1(!hovered);
						}
					},
				});
			},
		});
	};

	const handleMouseEnter1 = () => {
		setHovered(true);
		pendingExit.current = false; // Reset pending exit as we are hovering
		if (!isFlipped1) {
			flipCard1(false); // Flip to back when mouse enters
		}
	};

	const handleMouseLeave1 = () => {
		setHovered(false);
		if (isFlipped1) {
			// If card is already flipped, initiate flip back to front
			flipCard1(true);
		} else {
			// If not flipped yet, set pendingExit to trigger after animation
			pendingExit.current = true;
		}
	};

	const [isFlipped2, setIsFlipped2] = useState<boolean>(false);
	const [hovered2, setHovered2] = useState<boolean>(false); // Track if the mouse is inside
	const frontRef2 = useRef<HTMLDivElement>(null);
	const isAnimating2 = useRef(false); // Track if animation is ongoing
	const pendingExit2 = useRef(false); // Track if exit animation should trigger after "enter" completes

	const flipCard2 = (flipToFront: boolean) => {
		// If an animation is ongoing, mark pendingExit if we want to flip to front
		if (isAnimating2.current) {
			if (flipToFront) {
				pendingExit2.current = true;
			}
			return;
		}

		isAnimating2.current = true;
		const flipDuration = 0.6;

		gsap.to(frontRef2.current, {
			rotationY: 90,
			duration: flipDuration / 2,
			ease: "power2.in",
			onComplete: () => {
				setIsFlipped2(!flipToFront);

				gsap.to(frontRef2.current, {
					rotationY: 0,
					duration: flipDuration / 2,
					ease: "power2.out",
					onComplete: () => {
						isAnimating2.current = false; // Reset animation state

						// If an exit is pending, immediately trigger it
						if (pendingExit2.current) {
							pendingExit2.current = false;
							flipCard2(true); // Flip to front
						} else if (hovered2 !== isFlipped2) {
							// Otherwise, check if the mouse state has changed during the animation
							flipCard2(!hovered2);
						}
					},
				});
			},
		});
	};

	const handleMouseEnter2 = () => {
		setHovered2(true);
		pendingExit2.current = false; // Reset pending exit as we are hovering
		if (!isFlipped2) {
			flipCard2(false); // Flip to back when mouse enters
		}
	};

	const handleMouseLeave2 = () => {
		setHovered2(false);
		if (isFlipped2) {
			// If card is already flipped, initiate flip back to front
			flipCard2(true);
		} else {
			// If not flipped yet, set pendingExit to trigger after animation
			pendingExit2.current = true;
		}
	};

	return (
		<div
			className='h-[833px] relative flex flex-col items-center justify-center widthClass '
			id='gbSection'
		>
			<div className='absolute top-[160px] left-[458px] z-10'>
				<div className='relative'>
					<div
						className={`w-[273px] h-[339px] absolute top-0 left-0 flex items-center justify-center`}
						ref={frontRef1}
						onMouseEnter={handleMouseEnter1}
						onMouseLeave={handleMouseLeave1}
					>
						<img
							src='/assets/GoodBadSection/white-rectangle.svg'
							alt='White rectangle'
							className=''
						/>
						{!isFlipped1 && (
							<img
								src='/assets/GoodBadSection/halo.svg'
								alt='Halo'
								className='absolute top-[27px] left-[37px] z-10'
							/>
						)}

						{isFlipped1 ? (
							<div className='absolute w-fit -ml-[5.5px] -mt-[5.5px]'>
								<div className='text-squeeze-darkBlue text-[24px]  leading-[110.5%] font-poppinsM'>
									<p className=''>We can bridge</p>
									<p className=''>the gap between</p>
									<p className=''>
										where{" "}
										<span className='text-squeeze-pink font-poppinsBl'>
											you are
										</span>
									</p>
									<p className=''>
										and where{" "}
										<span className='text-squeeze-pink font-poppinsBl'>
											you
										</span>
									</p>
									<p className='text-squeeze-pink font-poppinsBl'>
										want to be
										<span className='font-poppinsB text-squeeze-darkBlue'>
											.
										</span>
									</p>
								</div>
							</div>
						) : (
							<div className='absolute top-[118px] left-[32px] z-10'>
								<p className='leading-none'>
									<span className='font-poppinsL text-[47px] text-squeeze-pink'>
										the
									</span>
									<br />
									<span className='font-poppinsBl text-[63px] text-squeeze-darkBlue'>
										GOOD
									</span>
									<br />
									<span className='font-poppinsBl text-[63px] text-squeeze-pink'>
										NEWS
									</span>
								</p>
							</div>
						)}
					</div>
				</div>
			</div>

			<div className='absolute bsolute top-[160px] left-[780px] z-10'>
				<div className='relative'>
					<div
						className={`w-[273px] h-[339px] absolute top-0 left-0 flex items-center justify-center `}
						ref={frontRef2}
						onMouseEnter={handleMouseEnter2}
						onMouseLeave={handleMouseLeave2}
					>
						<img
							src='/assets/GoodBadSection/black-rectangle.svg'
							alt='Black rectangle'
							className=''
						/>
						{!isFlipped2 && (
							<img
								src='/assets/GoodBadSection/horns.svg'
								alt='Horns'
								className='absolute top-[50px] left-[58px] z-10'
							/>
						)}

						{isFlipped2 ? (
							<div className='absolute w-fit -ml-[5.5px] -mt-[5.5px]'>
								<div className='text-white text-[26px] leading-[110.5%] font-poppinsEb uppercase'>
									<p className=''>Welcome</p>
									<p className=''>to the</p>
									<p className='text-squeeze-pink '>'No Bad</p>

									<p className='text-squeeze-pink '>
										News Zone'
										<span className='text-white'>!</span>
									</p>
								</div>
							</div>
						) : (
							<div className='absolute top-[118px] left-[32px] z-10'>
								<p className='leading-none'>
									<span className='font-poppinsL text-[47px] text-white'>
										the
									</span>
									<br />
									<span className='font-poppinsBl text-[63px] text-squeeze-pink'>
										BAD
									</span>
									<br />
									<span className='font-poppinsBl text-[63px] text-white'>
										NEWS
									</span>
								</p>
							</div>
						)}
					</div>
				</div>
			</div>

			<DesignElements />
		</div>
	);
};

export default GoodBad;
