import React from "react";

const DesignElements = () => {
	return (
		<>
			<img
				src='/assets/ServicesPage/NewHeightsSection/mountainsTR.svg'
				alt='Mountain range top right'
				className='absolute top-[140px] left-[680px] z-0'
			/>
			<img
				src='/assets/ServicesPage/NewHeightsSection/mountainsBL.svg'
				alt='Mountain range bottom left'
				className='absolute top-[555px] left-[450px] z-0'
			/>
		</>
	);
};

export default DesignElements;
