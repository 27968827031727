import React from 'react'
import { Link } from 'react-router-dom'

const ServiceLinks = () => {
  return (
    <>
			<Link
				to='/services'
				className='absolute top-[300px] cursor-pointer'
			>
				<img
					src='/assets/Mobile/Home/ServicesSection/1.svg'
					alt='Graphic design'
					className='transition-transform duration-300 hover:scale-105 -rotate-[3.91deg]'
				/>
			</Link>
			<Link
				to='/services'
				className='absolute top-[520px]  z-0 hover:z-30 cursor-pointer'
			>
				<img
					src='/assets/Mobile/Home/ServicesSection/2.svg'
					alt='Social Media Marketing'
					className='transition-transform duration-300 hover:scale-105 rotate-[2.24deg]'
				/>
			</Link>
			<Link
				to='/services'
				className='absolute top-[720px]  z-20 hover:z-30 cursor-pointer -rotate-[2.97deg]'
			>
				<img
					src='/assets/Mobile/Home/ServicesSection/3.svg'
					alt='Copywriting and dontent writing'
					className='transition-transform duration-300 hover:scale-105'
				/>
			</Link>
			<Link
				to='/services'
				className='absolute top-[980px]  z-0 hover:z-30 cursor-pointer'
			>
				<img
					src='/assets/Mobile/Home/ServicesSection/4.svg'
					alt='SEO'
					className='transition-transform duration-300 hover:scale-105 rotate-[1.62deg]'
				/>
			</Link>
		</>
  )
}

export default ServiceLinks