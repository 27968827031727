import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const ServicesParallaxElements = () => {
	useGSAP(() => {
		const timelineCapsuleTL = gsap.timeline({
			scrollTrigger: {
				trigger: "#serviceDetailsCont",
				scroller: "#scrollDemo",
				start: "top center",
				end: "center center",

				scrub: 3,
			},
		});
		timelineCapsuleTL.fromTo(
			"#capsuleTL",
			{ x: 410, y: 220, scale: 1, rotate: 0 },
			{ position: "relative", x: 410, y: 310, scale: 1, rotate: 0 }
		);

		const timelineCircleTL = gsap.timeline({
			scrollTrigger: {
				trigger: "#serviceDetailsCont",
				scroller: "#scrollDemo",
				start: "top center",
				end: "center center",

				scrub: 5,
			},
		});
		timelineCircleTL.fromTo(
			"#circleTL",
			{ x: -100, y: 200, scale: 1.25, rotate: 0 },
			{ position: "relative", x: 225, y: 345, scale: 1, rotate: 0 }
		);

		const timelineCapsuleTR = gsap.timeline({
			scrollTrigger: {
				trigger: "#serviceDetailsCont",
				scroller: "#scrollDemo",
				start: "top center",
				end: "center center",

				scrub: 4,
			},
		});
		timelineCapsuleTR.fromTo(
			"#capsuleTR",
			{ x: 930, y: 175, scale: 0.5, rotate: -15 },
			{ position: "relative", x: 930, y: 75, scale: 1, rotate: 0 }
		);

		const timelineCircleTR = gsap.timeline({
			scrollTrigger: {
				trigger: "#serviceDetailsCont",
				scroller: "#scrollDemo",
				start: "top center",
				end: "center center",

				scrub: 7,
			},
		});
		
		timelineCircleTR.fromTo(
			"#circleTR",
			{  x: 1300, y: 285, scale: 1.5, rotate: 0 },
			{ position: "relative", x: 775, y: 185, scale: 1, rotate: 0 }
		);
		
		const timelineCircleM = gsap.timeline({
			scrollTrigger: {
				trigger: "#serviceDetailsCont",
				scroller: "#scrollDemo",
				start: "top center",
				end: "bottom bottom",

				scrub: 7,
			},
		});
		timelineCircleM.fromTo(
			"#circleM",
			{ x: 522, y: 650, scale: 0.25, rotate: 0 },
			{ position: "relative", x: 522, y: 200, scale: 1, rotate: 0 }
		);

		const timelineCircleBL = gsap.timeline({
			scrollTrigger: {
				trigger: "#serviceDetailsCont",
				scroller: "#scrollDemo",
				start: "top center",
				end: "bottom bottom",

				scrub: 5,
			},
		});
		timelineCircleBL.fromTo(
			"#circleBL",
			{ x: 280, y: 370, scale: 2, rotate: 45 },
			{ position: "relative", x: 480, y: 170, scale: 1, rotate: 0 }
		);

		const timelineCapsuleBR = gsap.timeline({
			scrollTrigger: {
				trigger: "#serviceDetailsCont",
				scroller: "#scrollDemo",
				start: "top center",
				end: "bottom bottom",

				scrub: 4,
			},
		});
		timelineCapsuleBR.fromTo(
			"#capsuleBR",
			{ x: 1200, y: 200,  rotate: -50},
			{ position: "relative", x: 1200, y: 0,  rotate: 0 }
		);
	}, []);
	return (
		<>
			<img
				src='/assets/ServicesPage/PinkSections/ServiceDetails/capsuleTL.svg'
				alt='Top left yellow capsule'
				id='capsuleTL'
				className='z-20'
			/>
			<img
				src='/assets/ServicesPage/PinkSections/ServiceDetails/circleTL.svg'
				alt='Top left yellow circle'
				id='circleTL'
				className='z-0'
			/>
			<img
				src='/assets/ServicesPage/PinkSections/ServiceDetails/capsuleTR.svg'
				alt='Top right yellow capsule'
				id='capsuleTR'
				className='z-10'
			/>
			<img
				src='/assets/ServicesPage/PinkSections/ServiceDetails/circleTR.svg'
				alt='Top right yellow circle'
				id='circleTR'
				className='z-0'
			/>
			<img
				src='/assets/ServicesPage/PinkSections/ServiceDetails/circleM.svg'
				alt='Middle yellow circle'
				id='circleM'
				className='z-0'
			/>
			<img
				src='/assets/ServicesPage/PinkSections/ServiceDetails/circleBL.svg'
				alt='Bottom left yellow circle'
				id='circleBL'
				className=''
			/>
			<img
				src='/assets/ServicesPage/PinkSections/ServiceDetails/capsuleBR.svg'
				alt='Bottom right yellow capsule'
				id='capsuleBR'
				className='z-20'
			/>
		</>
	);
};

export default ServicesParallaxElements;
