import { toastDefaultOptions } from "lib/consts";
import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { contactService } from "services/contact";

const ContactForm = () => {
	const [name, setName] = useState<string>("");
	const [mail, setMail] = useState<string>("");
	const [message, setMessage] = useState<string>("");

	const [isSendingMessage, setIsSendingMessage] = useState<boolean>(false);

	const validateEmail = (mail: string) => {
		const mailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return mailRegex.test(mail);
	};

	const handleSubmit = (e: React.FormEvent) => {
		e.preventDefault();
		if (!name || !mail || !message) {
			toast.error("Please fill all the information.", toastDefaultOptions);
			return;
		}

		if (!validateEmail(mail)) {
			toast.error("Please enter a valid mail address.", toastDefaultOptions);
			return;
		}

		setIsSendingMessage(true);

		contactService
			.sendMessage(name, mail, message)
			.then((response) => {
				if (response.isFailed) {
					toast.error(
						"An error occured while sending the message",
						toastDefaultOptions
					);
				} else {
					toast.success("Message sent!", toastDefaultOptions);
				}
			})
			.catch(() => {
				toast.error(
					"An error has occured while sending the message.",
					toastDefaultOptions
				);
			})
			.finally(() => {
				setIsSendingMessage(false);
			});
	};
	return (
		<div className='flex flex-col w-full text-white h-fit  space-y-[24px]'>
			<div className='space-y-[10px]'>
				<p className='text-[18px] font-poppinsL -tracking-[2%] leading-[75%] '>
					Interested?
				</p>
				<p className='text-[30px] leading-[96%] font-poppinsEb text-squeeze-yellow'>
					Let's chat!
				</p>
			</div>
			<form className='flex flex-col w-full h-fit font-robotol text-[11px] space-y-[13px] '>
				<div className='w-full border-b-white h-fit border-b-[0.75px]'>
					<input
						type='text'
						name='Name'
						value={name}
						onChange={({ target: { value } }) => setName(value)}
						placeholder='Name'
						className='w-full placeholder-white bg-transparent focus:outline-none h-fit'
					/>
				</div>
				<div className='w-full border-b-white h-fit border-b-[0.75px]'>
					<input
						type='text'
						name='E-mail address'
						value={mail}
						onChange={({ target: { value } }) => setMail(value)}
						placeholder='Mail'
						className='w-full placeholder-white bg-transparent focus:outline-none h-fit'
					/>
				</div>
				<div className='w-full border-b-white  border-b-[0.75px] h-[70px]'>
					<textarea
						name='Message'
						value={message}
						onChange={({ target: { value } }) => setMessage(value)}
						placeholder='Message'
						className='w-full bg-transparent h-full text=justify-start  overflow-y-scroll placeholder-white'
					/>
				</div>
				<button
					onClick={handleSubmit}
					type='submit'
					disabled={isSendingMessage}
					className={`uppercase  border-squeeze-yellow flex items-center justify-center rounded-[46.06px] border-[0.75px] w-[80px] h-[32px] text-[12.24px] leding-[117.5px] font-poppinsSb text-squeeze-yellow `}
				>
					Submit
				</button>
			</form>

			<ToastContainer style={{ marginTop: "10vh" }} />
		</div>
	);
};

export default ContactForm;
