import HeadingTextAnimation from "components/HeadingTextAnimation";
import React from "react";

const AnimatedDesc = ({ isVisible }: { isVisible: boolean }) => {
	return (
		<div className='absolute top-[50%] -translate-y-1/2 right-[0px] font-robotol text-[20px] space-y-[17px] text-white '>
			<div className={`flex flex-col -space-y-[7px]`}>
				<HeadingTextAnimation
					smallText
					idx={"1"}
					isVisible={isVisible}
					text={`Picture this: each strategy we craft is like a bespoke `}
				/>

				<HeadingTextAnimation
					smallText
					idx={"1"}
					isVisible={isVisible}
					text='suit tailored precisely to your unique needs, wants,'
				/>

				<HeadingTextAnimation
					smallText
					idx={"1"}
					isVisible={isVisible}
					text='and requirements.'
				/>
			</div>
			<div className={`flex flex-col -space-y-[5px]`}>
				<HeadingTextAnimation
					smallText
					idx={"2"}
					isVisible={isVisible}
					text='We dive deep into your world, understanding your'
				/>
				<HeadingTextAnimation
					smallText
					idx={"2"}
					isVisible={isVisible}
					text='user persona and ensuring that every move we make'
				/>
				<HeadingTextAnimation
					smallText
					idx={"2"}
					isVisible={isVisible}
					text='is a step towards resonating with your audience, all'
				/>
				<HeadingTextAnimation
					smallText
					idx={"2"}
					isVisible={isVisible}
					text='while guiding them through your funnel.'
				/>
			</div>
			<div className={`flex flex-col -space-y-[5px]`}>
				<HeadingTextAnimation
					smallText
					idx={"3"}
					isVisible={isVisible}
					text="And just so you know - our decisions aren't left"
				/>
				<HeadingTextAnimation
					smallText
					idx={"3"}
					isVisible={isVisible}
					text='to guesswork: data is our guiding star, leading us to the'
				/>
				<HeadingTextAnimation
					smallText
					idx={"3"}
					isVisible={isVisible}
					text='	most informed choices.'
				/>
			</div>
		</div>
	);
};

export default AnimatedDesc;
