import React from "react";

const DesignElements = () => {
	return (
		<>
			<img
				src='/assets/Mobile/Services/NewHeightsSection/mountain-top.svg'
				alt='Mountain range top right'
				className='absolute top-[35px] left-[125px] z-0'
			/>
			<img
				src='/assets/Mobile/Services/NewHeightsSection/mountain-btm.svg'
				alt='Mountain range bottom left'
				className='absolute top-[555px] left-[-20px] z-0'
			/>
		</>
	);
};

export default DesignElements;
