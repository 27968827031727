import { usePopup } from "contexts/PopupContext";
import DesignElements from "./DesignElements";
import TextElements from "./TextElements";

const ImagineSection = ({ isVisible }: { isVisible: boolean }) => {

	const { setPopupOpen } = usePopup();

	return (
		<div className='relative flex flex-col items-center justify-center widthClass h-fit'>
			<div className='w-full h-[140px]'></div>
			<DesignElements />
			<div className='relative h-[681px] w-full flex justify-center items-center'>
				<div
					className={`space-y-[35px] text-[#0C1B31] flex flex-col justify-center items-center`}
				>
					<div className='absolute top-[164px] left-[355px] w-fit h-fit'>
						<div className='w-[805px] h-[97px] -rotate-[2.52deg] bg-[#FFD402] rounded-[10px] '></div>
					</div>
					<TextElements isVisible={isVisible} />
					<button
					onClick={()=>{setPopupOpen(true)}}
						className={`uppercase flex items-center justify-center rounded-full bg-squeeze-white border-2 hover:bg-squeeze-pink hover:text-white border-squeeze-pink w-[197px] h-[64px] text-[27px] font-poppinsSb text-squeeze-pink `}
					>
						let's go
					</button>
				</div>
			</div>
		</div>
	);
};

export default ImagineSection;
