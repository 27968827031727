import { ShortPost } from "lib/interfaces";
import { toTop } from "lib/utils";
import { Link } from "react-router-dom";

const Post = ({ post }: { post: ShortPost }) => {
	const apiUrl = `${process.env.REACT_APP_API_URL}`;
	return (
		<div className='grid w-full h-full grid-rows-2 '>
			<div
				className={`rounded-[27px] w-full overflow-hidden bg-cover self-center aspect-[16/10] ${
					post.thumbnail !== "" ? "bg-squeeze-yellow " : ""
				}`}
			>
				<div className='w-full h-full '>
					{post.thumbnail && (
						<img
							src={`${apiUrl}${post.thumbnail}`}
							alt=''
							className='object-cover w-full h-full'
						/>
					)}
				</div>
			</div>

			<div className='space-y-[4%] py-[4%] w-[80%] self-end h-full flex flex-col justify-between'>
				<div className='space-y-[4%] self-start '>
					<p
						className={`break:text-[14px] font-robotoB leading-[129%] break:tracking-[0.11rem] text-squeeze-pink uppercase`}
					>
						{post.category.title}
					</p>
					<p
						className={`font-poppinsB break:text-[28px] mobile-m:text-[20px] leading-[104%] break:-tracking-[0.015rem] text-squeeze-darkBlue`}
					>
						{post.title}
					</p>
					<p
						className={`font-robotol break:text-[15px] text-[13px] leading-[18px] text-squeeze-darkBlue`}
					>
						{post.description}
					</p>
				</div>
				<Link
					to={`/blog/${post.title}/${post.id}`}
					onClick={() => {
						toTop()
					}}
					className={`hover:bg-squeeze-pink hover:text-white break:px-[19px] tablet:px-[15px] tablet:py-[10px] px-[10px] py-[5px] break:py-[10px] h-fit w-fit uppercase border-squeeze-pink border-[0.73px] rounded-[38.67px] break:text-[12pt] text-sm  leading-[117.5%] font-poppinsSb text-squeeze-pink`}
				>
					Read more
				</Link>
			</div>
		</div>
	);
};

export default Post;
