import HeadingSection from "components/Blog/SingleBlog/HeadingSection";
import MainSection from "components/Blog/SingleBlog/MainSection";
import BlogFooters from "components/Footer/Blog/BlogFooters";
import BlogHeaders from "components/Header/BlogHeaders";
import { toastDefaultOptions } from "lib/consts";
import { BlogPost } from "lib/interfaces";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { blogService } from "services/blog";

const SingleBlog = () => {
	const { title, id } = useParams<{ title: string; id: string }>();
	const [post, setPost] = useState<BlogPost | null>(null);

	//Ne mozma da vidam kako e success tipot mozda treba da se smeni. vidi errow handling seegde zaso response na failure e cuden

	useEffect(() => {
		const fetchPost = () => {
			blogService
				.getPost(id ? id : "")
				.then((response) => {
					if (response?.success) {
						setPost(response.data.items);
					} else toast.error(response?.message, toastDefaultOptions);
				})
				.catch((Error) => {
					toast.error(Error, toastDefaultOptions);
				});
		};
		fetchPost();
	}, [id]);

	return (
		<div
			className='relative flex flex-col items-center justify-center overflow-visible h-fit overflow-x-clip'
			id='scrollDemo'
		>
			<BlogHeaders />
			<div className='max-w-[1254px] max-[1254px]:px-[3vw] w-full h-full mt-[15vh] laptop:my-[10vh]'>
				{post ? (
					<>
						<HeadingSection post={post} />
						<MainSection post={post} />
					</>
				) : (
					<div className='flex items-center justify-center w-full h-screen'>
						Post not found.
					</div>
				)}
			</div>
			<BlogFooters />
		</div>
	);
};

export default SingleBlog;
