import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const ServicesParallaxElements = () => {
	useGSAP(() => {
		const timelineCircleTL = gsap.timeline({
			scrollTrigger: {
				trigger: "#serviceDetailsCont",
				scroller: "#scrollDemo",
				start: "top center",
				end: "top 70%",
				scrub: 6,
			},
		});
		timelineCircleTL.fromTo(
			"#circleTL",
			{ x: 95, y: 65, scale: 1.5, rotate: 0 },
			{ position: "absolute", x: 95, y: 65, scale: 1, rotate: 0 }
		);

		const timelineCapsuleTR = gsap.timeline({
			scrollTrigger: {
				trigger: "#serviceDetailsCont",
				scroller: "#scrollDemo",
				start: "top center",
				end: "top 70%",
				scrub: 6,
			},
		});
		timelineCapsuleTR.fromTo(
			"#capsuleTR",
			{ x: 240, y: 156, scale: 0.5, rotate: -15 },
			{ position: "absolute", x: 240, y: 134.4, scale: 1, rotate: 0 }
		);

		const timelineCircleMS = gsap.timeline({
			scrollTrigger: {
				trigger: "#serviceDetailsCont",
				scroller: "#scrollDemo",
				start: "top center",
				end: "center center",
				scrub: 7,
			},
		});
		timelineCircleMS.fromTo(
			"#circleMS",
			{ x: 430, y: 416, scale: 1.5, rotate: 0 },
			{ position: "absolute", x: 330, y: 490, scale: 1, rotate: 0 }
		);

		const timelineCapsuleM = gsap.timeline({
			scrollTrigger: {
				trigger: "#serviceDetailsCont",
				scroller: "#scrollDemo",
				start: "top center",
				end: "center center",
				scrub: 4,
			},
		});
		timelineCapsuleM.fromTo(
			"#capsuleM",
			{ x: 280, y: 546, scale: 1, rotate: -15 },
			{ position: "absolute", x: 280, y: 520, scale: 1, rotate: 0 }
		);

		const timelineCircleM = gsap.timeline({
			scrollTrigger: {
				trigger: "#serviceDetailsCont",
				scroller: "#scrollDemo",
				start: "top center",
				end: "center center",
				scrub: 7,
			},
		});
		timelineCircleM.fromTo(
			"#circleM",
			{ x: 350, y: 572, scale: 0.35, rotate: 0 },
			{ position: "absolute", x: 250, y: 572, scale: 1, rotate: 0 }
		);

		const timelineCapsuleBR = gsap.timeline({
			scrollTrigger: {
				trigger: "#serviceDetailsCont",
				scroller: "#scrollDemo",
				start: "top center",
				end: "center center",
				scrub: 4,
			},
		});
		timelineCapsuleBR.fromTo(
			"#capsuleBR",
			{ x: 425, y: 976, scale: 1.2, rotate: -15 },
			{ position: "absolute", x: 325, y: 873.6, scale: 1, rotate: 0 }
		);

		const timelineCircleBL = gsap.timeline({
			scrollTrigger: {
				trigger: "#serviceDetailsCont",
				scroller: "#scrollDemo",
				start: "top center",
				end: "bottom bottom",
				scrub: 5,
			},
		});
		timelineCircleBL.fromTo(
			"#circleBL",
			{ x: 210, y: 1003.6, scale: 2, rotate: 45 },
			{ position: "absolute", x: -10, y: 1003.6, scale: 1, rotate: 0 }
		);
    
		const timelineCircleBR = gsap.timeline({
			scrollTrigger: {
				trigger: "#serviceDetailsCont",
				scroller: "#scrollDemo",
				start: "top center",
				end: "bottom bottom",
				scrub: 5,
			},
		});
		timelineCircleBR.fromTo(
			"#circleBR",
			{ x: 125, y: 1263.6, scale: 2, rotate: 45 },
			{ position: "absolute", x: 225, y: 1367.6, scale: 1, rotate: 0 }
		);
	}, []);
	return (
		<div className='w-[450px] h-[1900px] relative'>
			<img
				src='/assets/Mobile/Services/PinkSections/ServiceDetails/ParallaxElements/circleTL.svg'
				alt='Top left yellow circle'
				id='circleTL'
				className='z-0'
			/>
			<img
				src='/assets/Mobile/Services/PinkSections/ServiceDetails/ParallaxElements/capsuleTR.svg'
				alt='Top right yellow capsule'
				id='capsuleTR'
				className='z-0'
			/>
			<img
				src='/assets/Mobile/Services/PinkSections/ServiceDetails/ParallaxElements/circleMS.svg'
				alt='Small middle circle'
				id='circleMS'
				className='z-0'
			/>
			<img
				src='/assets/Mobile/Services/PinkSections/ServiceDetails/ParallaxElements/capsuleM.svg'
				alt='Middle capsule'
				id='capsuleM'
				className='z-20'
			/>
			<img
				src='/assets/Mobile/Services/PinkSections/ServiceDetails/ParallaxElements/circleM.svg'
				alt='Middle circle'
				id='circleM'
				className='z-0'
			/>
			<img
				src='/assets/Mobile/Services/PinkSections/ServiceDetails/ParallaxElements/capsuleBR.svg'
				alt='capsule bottom right'
				id='capsuleBR'
				className='z-0'
			/>
			<img
				src='/assets/Mobile/Services/PinkSections/ServiceDetails/ParallaxElements/circleBR.svg'
				alt='Bottom right yellow circle'
				id='circleBR'
				className='z-0'
			/>
			<img
				src='/assets/Mobile/Services/PinkSections/ServiceDetails/ParallaxElements/circleBL.svg'
				alt='Bottom left yellow circle'
				id='circleBL'
				className='z-0'
			/>
		</div>
	);
};

export default ServicesParallaxElements;
