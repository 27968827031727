import styles from "./bulbs.module.css";

const DesignElements = () => {
	return (
		<>
			<img
				src='/assets/ServicesPage/ImagineSection/stars.svg'
				alt='stars'
				className='absolute top-[170px] left-[200px] animate-pulse'
			/>
			<img
				src='/assets/ServicesPage/ImagineSection/star-bulb.svg'
				alt='star bulb'
				className='absolute top-[435px] right-[255px] animate-pulse '
			/>
			<img
				src='/assets/ServicesPage/ImagineSection/pink-bulb.svg'
				alt='pink bulb'
				className={`${styles.customBounce1} absolute top-[315px] right-[10px] rotate-[27.48deg]`}
			/>
			<img
				src='/assets/ServicesPage/ImagineSection/yellow-bulb.svg'
				alt='yellow bulb'
				className={`${styles.customBounce2} absolute top-[575px] right-[205px]  `}
			/>
		</>
	);
};

export default DesignElements;
