import HeadingTextAnimation from "components/HeadingTextAnimation";
import { usePopup } from "contexts/PopupContext";

const TextElements = ({ isVisible }: { isVisible: boolean }) => {
	const { setPopupOpen } = usePopup();

	return (
		<>
			<div className='text-squeeze-darkBlue z-20 w-fit absolute top-[297px] left-[293px] font-poppinsBl text-[80px] leading-[97%] -rotate-[7deg]'>
				<HeadingTextAnimation idx={"1"} isVisible={isVisible} text='READY TO' />
				<div className='flex gap-[20px]'>
					<HeadingTextAnimation idx={"2"} isVisible={isVisible} text='REACH' />

					<HeadingTextAnimation
						idx={"2"}
						isVisible={isVisible}
						text='NEW'
						extraClass='text-squeeze-pink'
					/>
				</div>
				<div className='flex gap-[5px] '>
					<HeadingTextAnimation
						idx={"3"}
						isVisible={isVisible}
						text='HEIGHTS'
						extraClass='text-squeeze-pink'
					/>

					<HeadingTextAnimation idx={"3"} isVisible={isVisible} text='?' />
				</div>
			</div>
			<div className='z-20 font-smoother absolute top-[480px] left-[730px] font-robotol text-[18px] text-[#0C1B31] leading-[114.5%] space-y-[17px]'>
				<div className=''>
					<HeadingTextAnimation
						smallText
						text={"Whether you’re looking to make your mark"}
						isVisible={isVisible}
						idx={"1"}
					/>
					<HeadingTextAnimation
						smallText
						text={"online, amplify your visual identity, or spark "}
						isVisible={isVisible}
						idx={"1"}
					/>
					<HeadingTextAnimation
						smallText
						text={"engaging conversations - our team of"}
						isVisible={isVisible}
						idx={"1"}
					/>
					<HeadingTextAnimation
						smallText
						text={"creatives is here to bring your vision to life."}
						isVisible={isVisible}
						idx={"1"}
					/>
				</div>
				<div>
					<HeadingTextAnimation
						smallText
						text={"Discover how we can tailor our expertise to"}
						isVisible={isVisible}
						idx={"2"}
					/>
					<HeadingTextAnimation
						smallText
						text={"your brand’s unique journey. We would love"}
						isVisible={isVisible}
						idx={"2"}
					/>
					<HeadingTextAnimation
						smallText
						text={"to hear your ideas, so be sure to get in touch."}
						isVisible={isVisible}
						idx={"2"}
					/>
				</div>
			</div>
			<button
				className={`z-20 absolute top-[670px] left-[730px] uppercase flex items-center justify-center rounded-full bg-squeeze-white border-2 hover:bg-squeeze-pink hover:text-white border-squeeze-pink w-[207px] h-[56px] text-[21px] font-poppinsSb text-squeeze-pink `}
				onClick={() => {
					setPopupOpen(true);
				}}
			>
				we're all ears
			</button>
		</>
	);
};

export default TextElements;
