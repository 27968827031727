import React, { useEffect, useRef } from "react";

const SingleEye = ({
	id,
	customStyle,
	folder,
	eyeClass,
	glareClass,
}: {
	id: string;
	customStyle?: string;
	folder: string;
	eyeClass: string;
	glareClass: string;
}) => {
	const eyeRef = useRef<HTMLImageElement>(null);

	useEffect(() => {
		let intervalId: NodeJS.Timeout;


		const rotateEyeSmoothly = () => {
			const eye = eyeRef.current;
			if (eye) {
				const randomAngle = Math.random() * 90 - 45;
				eye.style.transform = `rotate(${randomAngle}deg)`;
			}

			// Set a new random interval between 1.5 and 2.5 seconds
			clearInterval(intervalId);
			intervalId = setInterval(rotateEyeSmoothly, Math.random() * 1000 + 1500);
		};

		// Start the initial interval
		intervalId = setInterval(rotateEyeSmoothly, Math.random() * 1000 + 1500);

		return () => {
			clearInterval(intervalId);
		};
	}, []);

	const outerPath = `/assets/Mobile/Home/EyeSection/${folder}/outer-eye.svg`;
	const innerPath = `/assets/Mobile/Home/EyeSection/${folder}/inner-eye.svg`;
	const glarePath = `/assets/Mobile/Home/EyeSection/${folder}/glare.svg`;

	return (
		<div className={`${customStyle} relative`}>
			<img
				src={outerPath}
				alt="Outer eye"
				id={`anchor-${id}`}
				className="w-full h-full"
			/>
			<img
				id={`eye-${id}`}
				src={innerPath}
				alt="Inner eye"
				className={`absolute ${eyeClass} transition-transform duration-500 ease-in-out`} 
				ref={eyeRef}
			/>
			<img src={glarePath} alt="Glare" className={glareClass} />
		</div>
	);
};

export default SingleEye;
