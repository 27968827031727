import { copyToClipboard } from "lib/utils";

const Contact = () => {
	return (
		<div className='absolute bottom-0 text-[14px] mobile-l:text-[18px] font-roboto pb-[8vh] w-full px-[5vw] space-y-[13px] mobile-l:space-y-[16px]'>
			<div className='text-white leading-[99%] space-y-[13px] flex flex-col'>
				<div onClick={() => copyToClipboard("squeezecreative@gmail.com")}>
					squeezecreative@gmail.com
				</div>
				<div onClick={() => copyToClipboard("+389 78 232 232")}>
					+389 78 232 232
				</div>
			</div>
			<div className='border-b-[0.5px] border-b-white'></div>
			<div className='flex gap-[10px] text-squeeze-yellow leading-[42%]'>
				<a href='https://www.instagram.com/squeezecreativeagency/?hl=en'>
					instagram
				</a>
				<a href='https://www.linkedin.com/company/squeeze-creative-agency/?trk=public_post_feed-actor-name'>
					linkedin
				</a>
				<a href='https://www.facebook.com/profile.php?id=100095734291715&mibextid=ZbWKwL'>
					facebook
				</a>
			</div>
		</div>
	);
};

export default Contact;
