import React from "react";
import { FAQs } from "lib/consts";
import FAQItem from "./FAQItem";

const FAQList = () => {
	return (
		<div className='laptop:mb-[200px] laptop:mt-[150px] max-laptop:space-y-[20px]'>
			<div className='space-y-[9px] laptop:space-y-[12px] laptop:mb-[50px] max-laptop:text-center '>
				<p className='font-poppinsEb text-[27px] laptop:text-[64px] leading-[110%] tracking-[-2%] text-squeeze-yellow'>
					got any questions?
				</p>
				<p className='font-roboto text-[21px] laptop:text-[48px] leading-[110%] tracking-[-2%] text-white'>
					we've got you covered.
				</p>
			</div>
			<div className='grid grid-cols-1 w-fit items-start gap-[11px] laptop:hidden'>
				{FAQs.map((item, idx) => (
					<FAQItem key={idx} item={item} idx={idx} />
				))}
			</div>
			<div className='max-laptop:hidden grid grid-cols-1 laptop:grid-cols-2 gap-[11px] laptop:gap-[27px]'>
				<div className='flex flex-col gap-[11px]'>
					{FAQs.filter((_, idx) => idx % 2 === 0).map((item, idx) => (
						<FAQItem key={idx} item={item} idx={idx}/>
					))}
				</div>
				<div className='flex flex-col gap-[11px]'>
					{FAQs.filter((_, idx) => idx % 2 !== 0).map((item, idx) => (
						<FAQItem key={idx} item={item} idx={idx}/>
					))}
				</div>
			</div>
		</div>
	);
};

export default FAQList;
