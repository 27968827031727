import React from 'react'
import MobileAllAboutYou from './MobileAllAboutYou'
import MobileAnimatedDesc from './MobileAnimatedDesc'
import MobileMarquee from 'components/Home/YouSection/MoibleMarquee'

const MobileYouSection = ({ isVisible }: { isVisible: boolean }) => {
  return (
    <div className='h-[800px] relative flex flex-col items-center justify-center widthClass '>
    <div className='w-[450px] h-[600px] flex items-center justify-center '>
        <div className=' w-[450px] h-[400px] flex justify-center relative overflow-clip'>
            <MobileAllAboutYou />
            <MobileAnimatedDesc isVisible={isVisible} />
        </div>
        <div className='absolute -rotate-[7deg] top-[0px] '>
            <MobileMarquee trigger={"+"} />
        </div>
        <div className='absolute -rotate-[7deg] bottom-[0px]'>
            <MobileMarquee trigger={"-"} />
        </div>
    </div>
</div>
  )
}

export default MobileYouSection