const YellowFooter = () => {
	return (
		<div className='absolute bottom-[300px] right-0 z-20  w-[1512px] h-[807px] flex items-center justify-center'>
			<div className='w-screen bg-[#FFD402] absolute h-[728px] right-0 bottom-0'></div>
			<img
				src='/assets/BlogPage/yellow-bg-clip.svg'
				alt=''
				className='absolute top-0 right-0 '
			/>
		</div>
	);
};

export default YellowFooter;
