import React from "react";
import FAQList from "./FAQList";
import DesignElements from "./DesignElements";

const FAQ = () => {
	return (
		<div className='w-[450px] laptop:w-[1512px] h-fit flex flex-col items-center justify-center relative max-laptop:mb-[10vh]'>
			<div className="max-laptop:hidden">
				<DesignElements />
			</div>
			<FAQList />
		</div>
	);
};

export default FAQ;
