import { FAQ } from "lib/interfaces";
import { useState } from "react";

const FAQItem = ({ item, idx }: { item: FAQ; idx: number }) => {
	const [isOpen, setIsOpen] = useState<boolean>(false);

	return (
		<div
			className={`w-[300px] laptop:w-[441px] border-[0.98px] laptop:border-[1.5px] border-white text-white cursor-pointer flex justify-between items-center px-[20px] laptop:px-[36px]
				overflow-hidden
				transition-all duration-300 ease-in-out ${
					isOpen
						? "py-[16px] laptop:py-[30px] max-h-[500px]"
						: "max-h-[66px] laptop:max-h-[106px]"
				}
				${
					isOpen
						? "rounded-[10px] laptop:rounded-[20px] space-x-[15px]"
						: "rounded-[84.82px] laptop:rounded-[130px] space-x-[5px]"
				}
			`}
			onClick={() => setIsOpen((prev) => !prev)}
		>
			<div
				className={`flex items-center relative justify-between font-roboto`}
			>
				<div>
					<p
						className={`text-[15px] laptop:text-[22.5px] leading-[114.5%] tracking-[-2%] absolute w-[223px] laptop:w-[340px] top-1/2 transform -translate-y-1/2 transition-opacity duration-300 ${
							isOpen ? "opacity-0" : "opacity-100"
						}`}
					>
						{item.q}
					</p>

					<p
						className={`font-roboto text-[15px] laptop:text-[20px] leading-[114.5%] tracking-[-3%] transition-opacity duration-300 ${
							isOpen ? "opacity-100" : "opacity-0"
						}`}
					>
						{item.a}
					</p>
				</div>
			</div>

			<img
				src={`/assets/FAQ/arrowM.svg`}
				alt='arrow mobile'
				className={`transition-transform duration-300 ${
					isOpen ? "rotate-180" : "rotate-0"
				} laptop:hidden`}
			/>
			<img
				src={`/assets/FAQ/arrow.svg`}
				alt='arrow '
				className={`transition-transform duration-300 ${
					isOpen ? "rotate-180" : "rotate-0"
				} max-laptop:hidden`}
			/>
		</div>
	);
};

export default FAQItem;
