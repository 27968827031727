import React, { useState } from "react";

const MobileNewsletter = () => {
	const [email, setEmail] = useState<string>("");
	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setEmail(e.target.value);
	};
	return (
		<div className='flex items-center justify-center w-full bg-squeeze-yellow laptop:hidden'>
			<div className='space-y-[10px] flex flex-col items-center justify-center h-fit py-[5vh] px-[10vw] w-[70vw]'>
				<p className='font-poppinsSb text-lg leading-[129%] text-squeeze-darkBlue mb-[4px] space-y-[5px] whitespace-nowrap'>
					Get the{" "}
					<span className='font-poppinsB text-squeeze-pink'>freshest</span>{" "}
					content
					<div className='border-b-[0.73px] border-squeeze-darkBlue w-full'></div>
				</p>
				<div className='text-squeeze-darkBlue font-robotol text-xs leding-[129px] w-full justify-items-center whitespace-nowrap '>
					<p>Subscribe to our blog and get notified about every</p>
					<p>blog post we ever write 'till the end of time.</p>
				</div>
				<div className='flex flex-col space-y-[10px] items-center justify-between w-full leading-[117.5%]'>
					<input
						type='text'
						value={email}
						placeholder='Email address'
						className='font-poppinsL focus:outline-none text-[10px] placeholder:text-squeeze-darkBlue text-squeeze-darkBlue border-[0.75px] border-squeeze-darkBlue rounded-[92px] w-[30vw] min-w-[200px] h-fit bg-transparent px-[20px] py-2'
						onChange={handleChange}
					/>
					<button className='w-[10vw] min-w-[100px] h-fit hover:bg-squeeze-darkBlue cursor-pointer bg-squeeze-pink text-white rounded-[92px] flex items-center justify-center font-poppinsSb text-[10px] py-2'>
						SUBSCRIBE
					</button>
				</div>
			</div>
		</div>
	);
};

export default MobileNewsletter;
