import React from "react";
import AccordionItem from "./AccordionItem";

const MobileAccordionItems = () => {
	return (
		<div className='absolute top-[462px] left-[780px] right-[302px] space-y-[8px]'>
			<AccordionItem title='Reach out & share your needs' number={"01"}>
				<p>We are available 24/7 to discuss</p>
				<p>your business's unique needs and</p>
				<p>personal requirements.</p>
			</AccordionItem>
			<AccordionItem title='Get a tailored strategy' number={"02"}>
				<p>Based on your needs, our expert team</p>
				<p>will develop a bespoke strategy using</p>
				<p>user-centred methods & data-driven</p>
				<p>decision.</p>
			</AccordionItem>
			<AccordionItem title='Strategies in motion' number={"03"}>
				<p>Watch your business thrive through</p>
				<p>the performance reports we deliver</p>
				<p>to ensure full transparency.</p>
			</AccordionItem>
		</div>
	);
};

export default MobileAccordionItems;
