import { Heading } from "lib/interfaces";

export const extractHeadings = (htmlContent: string): Heading[] => {
	const div = document.createElement("div");
	div.innerHTML = htmlContent;

	const headingsArray: Heading[] = [];
	const headingsElements = div.querySelectorAll("h1, h2, h3, h4, h5, h6");

	headingsElements.forEach((el) => {
		const level = parseInt(el.tagName[1]);
		const text = (el as HTMLElement).innerText; // Casting to HTMLElement to access innerText
		const id = text
			.toLowerCase()
			.replace(/\s+/g, "-")
			.replace(/[^\w-]/g, "");

		el.id = id; // Setting an ID to each heading for the links to target
		headingsArray.push({ level, text, id });
	});

	return headingsArray;
};

export const formatDate = (date: Date | string): string => {
	const options: Intl.DateTimeFormatOptions = {
		year: "numeric",
		month: "long",
		day: "2-digit",
	};
	return new Date(date).toLocaleDateString("en-US", options);
};

export const toTop = () => {
	window.scrollTo({ top: 0, behavior: "smooth" });
};

export const copyToClipboard = (text: string) => {
	navigator.clipboard.writeText(text);
};

export const facebookShare = (title: string, link: string) => {
	const postTitle = encodeURIComponent(title);
	const postLink = encodeURIComponent(link);
	const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${postLink}&quote=${postTitle}`;
	window.open(facebookShareUrl, "_blank");
  };