import React from "react";
import Contact from "./Contact";
import MobileLinks from "./MobileLinks";

const OpenMobileHeader = ({ setIsOpen }: { setIsOpen: React.Dispatch<React.SetStateAction<boolean>> }) => {
	return (
		<div className='px-[5vw] fixed top-0 left-0 z-40 flex flex-col items-center justify-center w-screen h-screen bg-squeeze-pink'>
			<MobileLinks setIsOpen={setIsOpen} />
			<Contact />
		</div>
	);
};

export default OpenMobileHeader;
