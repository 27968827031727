const ContactSection = () => {
	return (
		<div
			className={`w-full space-y-[20px] font-roboto text-center absolute bottom-[5vh] whitespace-nowrap `}
		>
			<div
				className={`laptop-l:text-[17px] laptop:text-[12px] 4k:text-[32px] text-white`}
			>
				<p>squeezecreative@gmail.com</p>
				<p>+389 78 232 232</p>
			</div>
			<div className='lowercase gap-[20px] flex justify-center items-center text-squeeze-yellow leading-[74%] laptop-l:text-[15px] laptop:text-[12px] 4k:text-[30px]'>
				<a
					target='_blank'
					rel='noopener noreferrer'
					href='https://www.instagram.com/squeezecreativeagency/?hl=en'
					className=''
				>
					Instagram
				</a>
				<a
					target='_blank'
					rel='noopener noreferrer'
					href='https://www.linkedin.com/company/squeeze-creative-agency/?trk=public_post_feed-actor-name'
				>
					LinkedIn
				</a>
				<a
					target='_blank'
					rel='noopener noreferrer'
					href='https://www.facebook.com/profile.php?id=100095734291715&mibextid=ZbWKwL'
				>
					Facebook
				</a>
			</div>
		</div>
	);
};

export default ContactSection;
