
import { gsap } from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import { useGSAP } from "@gsap/react";
gsap.registerPlugin(ScrollTrigger);

const DesignElements = () => {
	useGSAP(() => {
		const timelineCloudTLO = gsap.timeline({
			scrollTrigger: {
				trigger: "#valuesSection",
				scroller: "#scrollDemo",
				start: "top center",
				end: "center center",

				scrub: 6,
			},
		});
		timelineCloudTLO.fromTo(
			"#cloudTLO",

			{
				position: "relative",
				x: -265,
				y: -70,
			},
			{
				position: "relative",
				x: -65,
				y: -70,
			}
		);
		const timelineCloudTL = gsap.timeline({
			scrollTrigger: {
				trigger: "#valuesSection",
				scroller: "#scrollDemo",
				start: "top center",
				end: "center center",

				scrub: 6,
			},
		});
		timelineCloudTL.fromTo(
			"#cloudTL",

			{
				position: "absolute",
				x: 205,
				y: -45,
			},
			{
				position: "absolute",
				x: 5,
				y: -45,
			}
		);

		const timelineCloudR = gsap.timeline({
			scrollTrigger: {
				trigger: "#valuesSection",
				scroller: "#scrollDemo",
				start: "top center",
				end: "center center",

				scrub: 8,
			},
		});
		timelineCloudR.fromTo(
			"#cloudR",

			{
        x:0,
        y:0,
				opacity: 10,
				scale: 0.25,
			},
			{
        x:0,
        y:0,
				opacity: 100,
				scale: 1,
			}
		);

		const timelineCloudBRO = gsap.timeline({
			scrollTrigger: {
				trigger: "#valuesSection",
				scroller: "#scrollDemo",
				start: "top center",
				end: "center center",

				scrub: 8,
			},
		});
		timelineCloudBRO.fromTo(
			"#cloudBRO",

			{
				position: "absolute",
				x: 350,
				y: 220,
			},
			{
				position: "absolute",
				x: 210,
				y: 220,
			}
		);

		const timelineCloudBR = gsap.timeline({
			scrollTrigger: {
				trigger: "#valuesSection",
				scroller: "#scrollDemo",
				start: "top center",
				end: "70% center",

				scrub: 5,
			},
		});
		timelineCloudBR.fromTo(
			"#cloudBR",

			{
				position: "absolute",
				x: 205,
				y: 207,
				scale: 0.25,
			},
			{
				position: "absolute",
				x: 205,
				y: 207,
				scale: 1,
			}
		);
	}, []);

	return (
		<>
			<img
				src='/assets/Mobile/Services/PinkSections/Values/star.svg'
				alt='star'
				className='absolute -top-[10px] left-[245px] z-0 animate-pulse'
			/>
			<img
				src='/assets/Mobile/Services/PinkSections/Values/stars.svg'
				alt='stars'
				className='absolute top-[145px] left-[300px] z-0 animate-pulse'
			/>
      	<img
				src='/assets/Mobile/Services/PinkSections/Values/starL.svg'
				alt='star left'
				className='absolute top-[300px] left-[80px] z-0 animate-pulse'
			/>
			<img
				src='/assets/Mobile/Services/PinkSections/Values/cloudTLO.svg'
				alt='Cloud outline top left'
				className='z-0 '
				id='cloudTLO'
			/>
			<img
				src='/assets/Mobile/Services/PinkSections/Values/cloudTL.svg'
				alt='Cloud top left'
				className='z-20'
				id='cloudTL'
			/>
          <img
            src='/assets/Mobile/Services/PinkSections/Values/cloudR.svg'
            alt='Cloud right'
            className='absolute top-[275px] right-[20px] z-20'
            id='cloudR'
          />
        <img
          src='/assets/Mobile/Services/PinkSections/Values/cloudBR.svg'
          alt='Cloud bototm middle'
          className='z-20'
          id='cloudBR'
        />
			<img
				src='/assets/Mobile/Services/PinkSections/Values/cloudBRO.svg'
				alt='Cloud outline bottom right'
				className='z-0 '
				id='cloudBRO'
			/>
		</>
	);
};

export default DesignElements;