import { gsap } from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import { useGSAP } from "@gsap/react";
gsap.registerPlugin(ScrollTrigger);

const DesignElements = () => {
	useGSAP(() => {
		const timelinePsStars = gsap.timeline({
			scrollTrigger: {
				trigger: "#gbSection",
				scroller: "#scrollDemo",
				start: "top bottom",
				end: "top center",

				scrub: 8,
			},
		});
		timelinePsStars.fromTo(
			"#asterL",

			{
				position: "relative",
				x: 1310,
				y: 100,
				scale: 0.75,
				rotate: -25,
			},
			{
				position: "relative",
				x: 1010,
				y: -175,
				scale: 1,
				rotate: 0,
			}
		);

		const timelinePsStars2 = gsap.timeline({
			scrollTrigger: {
				trigger: "#gbSection",
				scroller: "#scrollDemo",
				start: "top bottom",
				end: "top center",

				scrub: 6,
			},
		});
		timelinePsStars2.fromTo(
			"#asterS",

			{
				position: "relative",
				x: 1300,
				y: -150,
				scale: 1.25,
				rotate: 15,
			},
			{
				position: "relative",
				x: 960,
				y: -75,
				scale: 1,
				rotate: 0,
			}
		);
	}, []);

	return (
		<>
			<img
				src='/assets/GoodBadSection/yellow-stars.svg'
				alt='Yellow-stars'
				className='absolute top-[230px] left-[319px] animate-bounce'
			/>
			<img
				src='/assets/GoodBadSection/yellow-stripes.svg'
				alt='Yellow stripes'
				className='absolute top-[450px] left-[1050px] animate-pulse'
			/>
			<img
				src='/assets/GoodBadSection/shell-swirl.svg'
				alt='Shell swirl'
				className='absolute top-[356px] left-[67px] -rotate-[10deg]'
			/>
			<div id='asterS' className='self-start justify-self-start w-fit h-fit'>
				<img
					src='/assets/GoodBadSection/asterix-small.svg'
					alt='Asterix small'
					className=''
				/>
			</div>
			<div id='asterL' className='self-start justify-self-start w-fit h-fit'>
				<img
					src='/assets/GoodBadSection/asterix-large.svg'
					alt='Asterix large'
					className=''
				/>
			</div>
		</>
	);
};

export default DesignElements;
