import HeadingTextAnimation from 'components/HeadingTextAnimation'
import React from 'react'

const MobileAnimatedDesc = ({ isVisible }: { isVisible: boolean }) => {
  return (
    <div className='absolute top-[200px] font-robotol text-[14px] space-y-[7px] text-[#D9D9D9] '>
    <div className={`flex flex-col -space-y-[5px]`}>
        <HeadingTextAnimation
            smallText
            idx={"1"}
            isVisible={isVisible}
            text={`Picture this: each strategy we craft is like`}
        />

        <HeadingTextAnimation
            smallText
            idx={"1"}
            isVisible={isVisible}
            text='a bespoke suit tailored precisely to your'
        />

        <HeadingTextAnimation
            smallText
            idx={"1"}
            isVisible={isVisible}
            text='unique needs, wants, and requirements.'
        />
    </div>
    <div className={`flex flex-col -space-y-[5px]`}>
        <HeadingTextAnimation
            smallText
            idx={"2"}
            isVisible={isVisible}
            text='We dive deep into your world, understanding'
        />
        <HeadingTextAnimation
            smallText
            idx={"2"}
            isVisible={isVisible}
            text='your user persona and ensuring that every'
        />
        <HeadingTextAnimation
            smallText
            idx={"2"}
            isVisible={isVisible}
            text='move we make is a step towards resonating'
        />
        <HeadingTextAnimation
            smallText
            idx={"2"}
            isVisible={isVisible}
            text='with your audience, all while guiding them'
        />
         <HeadingTextAnimation
            smallText
            idx={"2"}
            isVisible={isVisible}
            text='through your funnel.'
        />
    </div>
    <div className={`flex flex-col -space-y-[5px]`}>
        <HeadingTextAnimation
            smallText
            idx={"3"}
            isVisible={isVisible}
            text="And just so you know - our decisions aren’t"
        />
        <HeadingTextAnimation
            smallText
            idx={"3"}
            isVisible={isVisible}
            text='left to guesswork: data is our guiding star,'
        />
        <HeadingTextAnimation
            smallText
            idx={"3"}
            isVisible={isVisible}
            text='leading us to the most informed choices.'
        />
    </div>
</div>
  )
}

export default MobileAnimatedDesc