import React from "react";
import ValueRects from "./ValueRects";
import DesignElements from "./DesignElements";

const MobileValuesSection = () => {
	return (
		<div
			className=' relative text-center w-[450px] h-[956px] pt-[100px]'
			id='valuesSection'
		>
			<ValueRects />
			<DesignElements />
		</div>
	);
};

export default MobileValuesSection;