import HeadingTextAnimation from "components/HeadingTextAnimation";

const TextElements = ({ isVisible }: { isVisible: boolean }) => {
	return (
		<>
			<div className=' font-poppinsB text-[121px] leading-[97%] whitespace-pre-line flex flex-col justify-center items-center'>
				<div className='relative '>
					
					<HeadingTextAnimation
						idx={"1"}
						isVisible={isVisible}
						text='where'
						extraClass=''
					/>
				</div>

				<HeadingTextAnimation
					idx={"2"}
					isVisible={isVisible}
					text='imagination'
					img
				/>

				<HeadingTextAnimation
					idx={"3"}
					isVisible={isVisible}
					text='meets results'
					extraClass='text-squeeze-pink -mt-[15px]'
				/>
			</div>
			<div className="flex flex-col space-y-[26px]">
				
			<p className={`font-robotol leading-[129%] text-[32px] text-center`}>
				We believe in your business’s potential.
			</p>
			<p className={`font-robotol leading-[129%] text-[32px] text-center`}>
				Discover the range of creative solutions we offer
				<br />
				to elevate your brand and drive unparalleled results.
			</p>
			</div>
		</>
	);
};

export default TextElements;
