import React, {
	createContext,
	useState,
	useContext,
	ReactNode,
	useEffect,
} from "react";

interface PopupContextType {
	popupOpen: boolean;
	setPopupOpen: React.Dispatch<React.SetStateAction<boolean>>;

	bgName: string;
	setBgName: React.Dispatch<React.SetStateAction<string>>;

	bgTrigger: boolean;
	setBgTrigger: React.Dispatch<React.SetStateAction<boolean>>;
}

const PopupContext = createContext<PopupContextType | undefined>(undefined);

export const PopupProvider: React.FC<{ children: ReactNode }> = ({
	children,
}) => {
	const [popupOpen, setPopupOpen] = useState<boolean>(false);
	const [bgName, setBgName] = useState<string>("false");
	const [bgTrigger, setBgTrigger] = useState<boolean>(false);

	const darkPopupSections = [
		"cardsTrustGoodBad",
		"getThere",
		"servicesSection",
		"closingSection",
		"pinkSections",
		"newHeights",
		"faq",
	];

	useEffect(() => {
		if (!darkPopupSections.includes(bgName)) {
			setBgTrigger(true);
		} else {
			setBgTrigger(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [bgName]);

	return (
		<PopupContext.Provider
			value={{
				popupOpen,
				setPopupOpen,
				bgName,
				setBgName,
				bgTrigger,
				setBgTrigger,
			}}
		>
			{children}
		</PopupContext.Provider>
	);
};

export const usePopup = (): PopupContextType => {
	const context = useContext(PopupContext);
	if (context === undefined) {
		throw new Error("usePopup must be used within a PopupProvider");
	}
	return context;
};
