const ClosingParallaxElements = () => {
	return (
		<>
			<img
				src='/assets/ClosingSection/rect-mid-right.svg'
				alt='Midlle yellow rectangle'
				id='csRectMR'
			/>
			<img
				src='/assets/ClosingSection/rect-top-right.svg'
				alt='Top right yellow rectangle'
				id='csRectTR'
			/>
			<img
				src='/assets/ClosingSection/rect-left.svg'
				alt='Left yellow rectangle'
				id='csRectL'
			/>
			<img
				src='/assets/ClosingSection/rect-bot-right.svg'
				alt='Bottom right yellow rectangle'
				id='csRectBR'
			/>
		</>
	);
};

export default ClosingParallaxElements;
