"use client";

import { useEffect, useReducer, useRef, useState } from "react";

export default function AccordionItem({
	title,
	children,
	number,
	eClass,
}: {
	title: string;
	children: React.ReactNode;
	number: string;
	eClass?: string;
}) {
	const [isOpen, setIsOpen] = useState<boolean>(false);

	return (
		<div
			className={`w-[450px] min-h-[68px] border-[2.23px] ${
				isOpen ? "border-none bg-squeeze-pink" : "border-squeeze-darkBlue"
			} rounded-[16.57px] relative`}
		>
			<div
				className={`flex flex-col transition ease-in-out `}
				onMouseEnter={() => {
					setIsOpen(true);
				}}
				onMouseLeave={() => {
					setIsOpen(false);
				}}
			>
				<div className={` `}>
					<div
						className={`relative w-full max-h-[68px] transition-[height] duration-300 ${
							isOpen ? "h-[38px]" : "h-[68px]"
						}`}
					>
						<p
							className={`absolute top-[13px] left-[13px] font-poppins text-[18.47px] ${
								isOpen ? "text-white" : "text-squeeze-pink"
							}`}
						>
							{number}
						</p>
						<p
							className={`${
								isOpen ? "opacity-0" : "opacity-100"
							}  absolute bottom-[8px] right-[25px] text-[22px] font-poppinsM text-squeeze-darkBlue`}
						>
							{title}
						</p>
					</div>
					<div className='max-laptop:col-span-2 lg:col-span-2 '></div>
				</div>

				<div
					className={` overflow-hidden  h-fit transition-max-h duration-300 ${
						isOpen ? `max-h-[120px]` : "max-h-0"
					} w-full `}
				>
					<div
						className={` w-[440px] relative h-full flex items-end justify-end `}
					>
						<div className='whitespace-nowrap h-full w-full pl-[60px] pb-[20px] text-[22.2px] font-roboto text-white leading-[103.5%] -tracking-[2%]'>
							{children}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
