import React from "react";

const AllAboutYou = () => {
	return (
		<>
			<img
				src='/assets/YouSection/star.svg'
				alt='Yellow star'
				className='absolute top-[350px] '
			/>

			<p className='absolute top-[280px] left-[76px]  text-white font-poppinsB text-[80px] -rotate-[7deg] uppercase'>
				we’re all{" "}
			</p>
			<p className='absolute top-[360px] left-[175px]  text-white font-poppinsB text-[80px] -rotate-[7deg] uppercase'>
				about
			</p>
			<img
				src='/assets/YouSection/yellow-you.svg'
				alt="Yellow 'you'"
				className=' absolute top-[460px] left-[110px]  z-10 '
			/>
		</>
	);
};

export default AllAboutYou;
