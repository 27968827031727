"use client";

import { useState } from "react";

export default function MobileAccordionItem({
	title,
	children,
	number,
	eClass,
}: {
	title: string;
	children: React.ReactNode;
	number: string;
	eClass?: string;
}) {
	const [isOpen, setIsOpen] = useState<boolean>(false);

	return (
		<div
			className={`w-[263px] min-h-[45px] border-[0.9px] ${
				isOpen ? "border-none bg-squeeze-pink rounded-[16.57px]" : "bg-squeeze-yellow rounded-[54.15px] border-squeeze-darkBlue"
			}  relative`}
		>
			<div
				className={`flex flex-col transition ease-in-out `}
				onClick={() => {
					setIsOpen((prev)=>!prev);
				}}
				
			>
				<div className={` `}>
					<div
						className={`relative w-full max-h-[39.59px] transition-[height] duration-300 ${
							isOpen ? "h-[28px]" : "h-[39.59px]"
						}`}
					>
						<p
							className={`absolute top-[5px] left-[13px] font-poppins text-[11.4px] ${
								isOpen ? "text-white" : "text-squeeze-pink"
							}`}
						>
							{number}
						</p>
						<div
							className={`${
								isOpen ? "opacity-0" : "opacity-100"
							}  absolute bottom-[1px] left-[30px] gap-[7px] text-[13px] font-poppinsM text-squeeze-darkBlue flex w-[215px] whitespace-nowrap justify-between `}
						>
							{title}
							<img src="/assets/Mobile/Home/GetThereSection/arrow-down.svg" alt="arrow down" />
						</div>
					</div>
					<div className='max-laptop:col-span-2 lg:col-span-2 '></div>
				</div>

				<div
					className={` overflow-hidden  h-fit transition-max-h duration-300 ${
						isOpen ? `max-h-[60px]` : "max-h-0"
					} w-full `}
				>
					<div
						className={`w-[225px] relative text-[13px] font-roboto text-white leading-[103.5%] -tracking-[2%]`}
					>
						<div className='whitespace-nowrap flex flex-col h-fit w-fit pl-[30px] pb-[5px]'>
							{children}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
