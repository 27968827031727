const DesignElements = () => {
	return (
		<>
			<img
				src='/assets/FAQ/birdsTR.svg'
				alt='Birds top right'
				className='absolute -top-[35px] left-[1170px]'
			/>
			<img
				src='/assets/FAQ/qMarkTRS.svg'
				alt='?'
				className='absolute top-[35px] left-[1080px]'
			/>
			<img
				src='/assets/FAQ/qMarkTR.svg'
				alt='Big ?'
				className='absolute top-[15px] left-[1260px]'
			/>
			<img
				src='/assets/FAQ/birdsBL.svg'
				alt='Birds bottom left'
				className='absolute top-[740px] -left-[60px]'
			/>
			<img
				src='/assets/FAQ/qMarkBL.svg'
				alt='Bottom left ?'
				className='absolute top-[875px] left-[150px]'
			/>
		</>
	);
};

export default DesignElements;
