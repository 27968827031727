import SingleEye from "./SingleEye";

const Eyes = () => {
	return (
		<>
			<div className='w-fit h-fit absolute top-[169px] left-[158px]'>
				<SingleEye
					folder='TopLeft'
					id='1'
					eyeClass='top-[11px] left-[150px]'
					glareClass='absolute top-[53px] left-[147px]'
				/>
				
			</div>
			<div className='w-fit h-fit absolute top-[350px] left-[1060px]'>
				<SingleEye
					folder='TopRight'
					id='2'
					eyeClass='top-[11px] left-[68px]'
					glareClass='absolute top-[25px] left-[64px]'
				/>
			</div>
			<div className='w-fit h-fit absolute top-[430px] left-[92px]'>
				<SingleEye
					folder='LeftMiddle'
					id='3'
					eyeClass='top-[3px] left-[41px]'
					glareClass='absolute top-[40px] left-[60px]'
				/>
			</div>
			<div className='w-fit h-fit absolute top-[815px] left-[185px]'>
				<SingleEye
					folder='BottomLeft'
					id='4'
					eyeClass='top-[7px] left-[90px]'
					glareClass='absolute top-[50px] left-[100px]'
				/>
			</div>
			<div className='w-fit h-fit absolute top-[805px] left-[1010px]'>
				<SingleEye
					folder='BottomRight'
					id='5'
					eyeClass='top-[9px] left-[110px]'
					glareClass='absolute top-[40px] left-[113px]'
				/>
			</div>
		</>
	);
};

export default Eyes;
