import React from "react";

const Arrows = () => {
	return (
		<>
			<img
				className='absolute top-[539px] left-[260px] animate-pulse'
				src='/assets/GetThereSection/large-arrow.svg'
				alt='Large arrow'
			/>
			<img
				className='absolute top-[255px] left-[724px] animate-bounce'
				src='/assets/GetThereSection/small-arrow.svg'
				alt='Small arrow'
			/>
		</>
	);
};

export default Arrows;
