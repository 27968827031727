import HeadingTextAnimation from "components/HeadingTextAnimation";
import React from "react";

const EyeSectionText = ({ isVisible }: { isVisible: boolean }) => {
	return (
		<div className='-space-y-[27px] absolute top-[460px] left-1/2 transform -translate-x-1/2 flex flex-col items-center justify-center'>
	
	  
			<div>
				<HeadingTextAnimation
					
					text='Look at us'
					extraClass=' font-poppinsEb text-[90px]'
					idx='1'
					isVisible={isVisible}
				/>
			</div>

			<div className='flex w-fit h-fit text-[80px] text-white font-poppinsL'>
				<div>
					<HeadingTextAnimation
						
						text={"being all "}
						isVisible={isVisible}
						idx={"2"}
						extraClass='mr-[28px] '
					/>
				</div>
				<div>
					<HeadingTextAnimation
						
						text={"bark"}
						isVisible={isVisible}
						idx={"2"}
						extraClass='text-squeeze-yellow font-poppinsB'
					/>
				</div>
				<div>
					<HeadingTextAnimation
						
						text={","}
						isVisible={isVisible}
						idx={"2"}
						extraClass=''
					/>
				</div>
			</div>
			<div className='flex w-fit h-fit text-[80px] text-white font-poppinsL'>
				<div>
					<HeadingTextAnimation
						
						text={"no"}
						isVisible={isVisible}
						idx={"3"}
						extraClass='mr-[28px] '
					/>
				</div>
				<div>
					<HeadingTextAnimation
						
						text={"bite"}
						isVisible={isVisible}
						idx={"3"}
						extraClass='text-squeeze-yellow mr-[28px] font-poppinsB'
					/>
				</div>
				<div>
					<HeadingTextAnimation
						
						text={"huh?"}
						isVisible={isVisible}
						idx={"3"}
						extraClass=' '
					/>
				</div>
			</div>
		</div>
	);
};

export default EyeSectionText;
