import HeadingTextAnimation from "components/HeadingTextAnimation";

const TextElements = ({ isVisible }: { isVisible: boolean }) => {
	return (
		<>
			<div className=' font-poppinsB text-[48px] max-laptop:mx-[5%] laptop:text-[121px] leading-[97%] flex flex-col justify-start items-center'>
				<div className='relative '>
					<img
						src='/assets/UnleashSection/rectangle.svg'
						alt='yellow rectangle'
						className='absolute laptop:top-[4px] top-[8px] scale-110 max-laptop:w-[450px]  '
					/>
					<HeadingTextAnimation
						idx={"1"}
						isVisible={isVisible}
						text='unleash'
						extraClass=''
					/>
				</div>

				<HeadingTextAnimation
					idx={"2"}
					isVisible={isVisible}
					text={`your business’`}
					extraClass="max-laptop:hidden"
				/>
				
				<HeadingTextAnimation
					idx={"2"}
					isVisible={isVisible}
					text={`your`}
					extraClass="laptop:hidden"
				/>
				
				<HeadingTextAnimation
					idx={"2"}
					isVisible={isVisible}
					text={`business’`}
					extraClass="laptop:hidden"
				/>

				<HeadingTextAnimation
					idx={"3"}
					isVisible={isVisible}
					text='full potential'
					extraClass='text-squeeze-pink'
				/>
			</div>
			<p className={`font-robotol leading-[129%] text-[18px] laptop:text-[32px] text-center`}>
				Discover user-centric & data-driven <br className="laptop:hidden"/> marketing
				<br className="max-laptop:hidden"/>
				strategies that will bring<br className="laptop:hidden"/> you unprecedented results.
			</p>
		</>
	);
};

export default TextElements;
