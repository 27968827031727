import React from "react";
import MobileHeader from "./MobileHeaders/MobileHeader";
import Header from "./Header";

const Headers = () => {
	return (
		<>
			<div
				id='header'
				className='max-laptop:hidden fixed top-0 z-[300] flex items-center w-full bg-white h-fit py-[10px]'
			>
				<Header />
			</div>
			<div className='fixed top-0 z-[300] flex items-center w-full bg-white h-fit py-[10px] laptop:hidden'>
				<MobileHeader />
			</div>
		</>
	);
};

export default Headers;
