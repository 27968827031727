import React from 'react'
import TextElements from './TextElements'
import DesignElements from './DesignElements'

const MobileImagineSection = ({isVisible}:{isVisible:boolean}) => {
  return (
    <div className='h-[750px] w-[450px] flex mt-[200px] items-cetner justify-center relative'>
        <TextElements isVisible={isVisible}/>
        <DesignElements />
    </div>
  )
}

export default MobileImagineSection