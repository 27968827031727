import HeadingTextAnimation from "components/HeadingTextAnimation";

const TextElements = ({ isVisible }: { isVisible: boolean }) => {
	return (
		<>
			<div className=' text-squeeze-darkBlue z-20 w-fit absolute top-[200px] font-poppinsBl text-[44px] leading-[97%] -rotate-[7deg]'>
				<HeadingTextAnimation
					idx={"1"}
					isVisible={isVisible}
					text='READY TO'
					smallText
				/>
				<div className='flex gap-[5px]'>
					<HeadingTextAnimation
						idx={"2"}
						isVisible={isVisible}
						text='REACH'
						smallText
					/>

					<HeadingTextAnimation
						idx={"2"}
						isVisible={isVisible}
						text='NEW'
						extraClass='text-squeeze-pink'
						smallText
					/>
				</div>
				<div className='flex gap-[5px] '>
					<HeadingTextAnimation
						idx={"3"}
						isVisible={isVisible}
						text='HEIGHTS'
						extraClass='text-squeeze-pink'
						smallText
					/>

					<HeadingTextAnimation
						idx={"3"}
						isVisible={isVisible}
						text='?'
						smallText
					/>
				</div>
			</div>
			<div className='z-20 font-smoother absolute top-[360px] font-robotol text-[14px] text-[#0C1B31] leading-[114.5%] space-y-[17px]'>
				<div className=''>
					<HeadingTextAnimation
						smallText
						text={"Whether you’re looking to make your mark"}
						isVisible={isVisible}
						idx={"1"}
					/>
					<HeadingTextAnimation
						smallText
						text={"online, amplify your visual identity, or spark "}
						isVisible={isVisible}
						idx={"1"}
					/>
					<HeadingTextAnimation
						smallText
						text={"engaging conversations - our team of"}
						isVisible={isVisible}
						idx={"1"}
					/>
					<HeadingTextAnimation
						smallText
						text={"creatives is here to bring your vision to life."}
						isVisible={isVisible}
						idx={"1"}
					/>
				</div>
				<div>
					<HeadingTextAnimation
						smallText
						text={"Discover how we can tailor our expertise to"}
						isVisible={isVisible}
						idx={"2"}
					/>
					<HeadingTextAnimation
						smallText
						text={"your brand’s unique journey. We would love"}
						isVisible={isVisible}
						idx={"2"}
					/>
					<HeadingTextAnimation
						smallText
						text={"to hear your ideas, so be sure to get in touch."}
						isVisible={isVisible}
						idx={"2"}
					/>
				</div>
			</div>
			<button
				className={`z-20 absolute top-[510px]  uppercase flex items-center justify-center bg-transparent border-[0.9px] rounded-[47.55px]  border-squeeze-pink w-[150px] h-[39px] text-[16.25px] font-poppinsSb text-squeeze-pink `}
				onClick={() => {
					const scroller = document.getElementById("scrollDemo");
					if (scroller) {
						scroller.scrollTo({
							top: scroller.scrollHeight,
							behavior: "smooth",
						});
					}
				}}
			>
				we're all ears
			</button>
		</>
	);
};

export default TextElements;
